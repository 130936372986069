import Tabbed from "../../components/Tabbed"

const tabs = [
  {
    label: 'Open dagen',
    path: '/admin'
  },
  {
    label: 'Afgelopen open dagen',
    path: '/admin/past'
  },
  {
    label: 'Openstaande betalingen',
    path: '/admin/payments'
  },
  {
    label: 'Wachtlijst',
    path: '/admin/waiting-list'
  },
  {
    label: 'Instellingen',
    path: '/admin/settings'
  }
]

const AdminPage = ({ children }) => {
  return <Tabbed tabs={tabs}>
      {children}
    </Tabbed>
}

export default AdminPage
