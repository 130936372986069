import { Box } from "@mui/system"
import { Chip } from "@mui/material"
import { humanReadableDate } from "../../functions"
import { useQuery } from "react-query"
import SubscriptionsClient from "../../clients/Subscriptions"
import Loader from "../../components/Loader"
import DataTable from "../../components/DataTable"

const subscriptionsClient = new SubscriptionsClient()

const statuses = {
  init: {
    label: 'Wachtrij',
    color: 'primary'
  },
  sent: {
    label: 'Verstuurd',
    color: 'success'
  },
  error: {
    label: 'Fout',
    color: 'warning'
  }
}

const columns = [
  {
    field: 'name',
    headerName: 'Naam',
  },
  {
    field: 'email',
    headerName: 'E-mailadres'
  },
  {
    field: 'date',
    headerName: 'Datum aanmelding',
    valueGetter: params => params.meta?.created,
    renderCell: params => <>{humanReadableDate(new Date(params.meta?.created))}</>
  },
  {
    field: 'sent',
    headerName: 'Verstuurd',
    valueGetter: params => params.meta?.modified,
    renderCell: params => <>{params.meta?.modified ? humanReadableDate(new Date(params.meta?.modified)) : null}</>
  },
  {
    field: 'status',
    headerName: 'Status',
    renderCell: params => (
      statuses[params.status]
        ? <Chip label={statuses[params.status].label} color={statuses[params.status].color}/>
        : params.status
    )
  }
]

const WaitingList = () => {
  const { isLoading, data: subscriptions } = useQuery('waiting-list', subscriptionsClient.getQueue, { retry: false })

  if (isLoading) {
    return <Loader/>
  }

  return <>
    <Box sx={{ py: 2 }}>
      <DataTable
        columns={columns}
        rows={subscriptions}
      />
    </Box>
  </>
}

export default WaitingList
