import { Box } from '@mui/system'
import { CircularProgress } from '@mui/material'

const Loader = () => {
  return <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
    <CircularProgress/>
  </Box>
}

export default Loader
