import auth from "./auth";

const authFetch = async (url, options) => {
  options = options || {}

  if (!auth.isLoggedIn()) {
    if (!auth.canRefresh()) {
      console.error('Not logged in and can\'t refresh')
      return
    }
    const result = await auth.refresh()
    if (!result) {
      return
    }
  }

  if (!options.headers) {
    options.headers = {}
  }
  options.headers.Authorization = `Bearer ${auth.getToken()}`

  return fetch(url, options)
}

export default authFetch
