import Stepped from "../../components/Stepped"
import SelectDate from "./SelectDate"
import Details from "./Details"
import Payment from "./Payment"
import Confirm from "./Confirm"

const steps = [
  {
    label: 'Datum selecteren',
    component: SelectDate
  },
  {
    label: 'Gegevens invullen',
    component: Details
  },
  {
    label: 'Online betalen',
    component: Payment,
    skipWhen: values => !values.subscription
  },
  {
    label: 'Klaar',
    component: Confirm
  }
]

const Subscribe = () => {
  return <Stepped steps={steps}/>
}

export default Subscribe
