import { Box } from "@mui/system"
import { Container } from "@mui/material"
import Header from "../components/Header"
import Guard from "../components/Guard"
import { Outlet } from "react-router-dom"

const Admin = () => {
  return <>
    <Header/>
    <Container>
      <Box sx={{ p: 2 }}>
        <Guard user>
          <Outlet/>
        </Guard>
      </Box>
    </Container>
  </>
}

export default Admin
