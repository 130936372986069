import { Box } from '@mui/system'
import { Button, Typography } from '@mui/material'
import { Formik, Form, Field } from 'formik'
import FormikTextField from '../form/FormikTextField'
import useSettings from "../../hooks/useSettings"

const WaitingListForm = ({ onPrevious, onSubmit }) => {
  const settings = useSettings()

  const initialValues = {
    name: '',
    email: ''
  }

  const handleSubmit = (values, { setSubmitting }) => {
    if (onSubmit) {
      onSubmit(values)
    }
    setSubmitting(false)
  }

  return <Formik
    initialValues={initialValues}
    // validationSchema={validationSchema}
    onSubmit={handleSubmit}
  >
    <Form>
      <Typography variant="h4">
        Ontvang een bericht wanneer er nieuwe data worden toegevoegd
      </Typography>
      <Typography variant="paragraph">
        {/*Je hebt aangegeven interesse te hebben in de Hotelschool open dagen.<br/>*/}
        {/*Je hebt aangegeven dat de data van de open dagen je niet goed uitkomen. <br/>
        Je kunt hier je gegevens achterlaten. Als er nieuwe data beschikbaar komen, krijg je eenmalig van ons een mail.*/}

        <div dangerouslySetInnerHTML={{ __html: settings.homeWaitingList }}></div>

      </Typography>
      <Box sx={{ mt: 2 }}>
        <Field
          component={FormikTextField}
          name="name"
          label="Naam"
          fullWidth
        />
      </Box>
      <Box sx={{ mt: 2 }}>
        <Field
          component={FormikTextField}
          type="email"
          name="email"
          label="E-mailadres"
          fullWidth
        />
      </Box>
      <Box sx={{ mt: 2, display: 'flex' }}>
        {onPrevious && <>
          <Button variant="outlined" onClick={onPrevious}>Vorige</Button>
          <Box sx={{ flexGrow: 1 }}/>
        </>}
        <Button type="submit" variant="contained">Volgende</Button>
      </Box>
    </Form>
  </Formik>
}

export default WaitingListForm
