const messages = {
  DataTable: {
    rowsPerPage: 'Rows per page',
    rows: 'rows',
    totalRows: 'Total rows',
    of: 'of'
  }
}

export default messages
