import { Button, Radio, RadioGroup, Table, TableBody, TableCell, TableHead, TableRow, Typography, Grid } from "@mui/material"
import { Box } from "@mui/system"
import { useState } from "react"
import { dateFromString, humanReadableDate } from "../../functions"
import { useQuery } from "react-query"
import LinearProgressWithLabel from "../LinearProgressWithLabel"
import { Field, Form, Formik } from "formik"
import FormikTextField from "../form/FormikTextField"
import Opendays from "../../clients/Opendays"
import SubscriptionsClient from "../../clients/Subscriptions"

const opendaysClient = new Opendays()
const subscriptionsClient = new SubscriptionsClient()

const EditSubscription = ({ subscription, onReady, onCancel }) => {

  const FormField = ({ width, children }) => {
    return <Grid item xs={width||12}>
      <Box sx={{ p: 1 }}>
        {children}
      </Box>
    </Grid>
  }

  const onSubmit = async (values, actions) => {

    await subscriptionsClient.update(subscription.id, values)

    if (onReady) {
      onReady()
    }
  }

  var form_values = {
    /*firstName: subscription.firstName,
    middleName: subscription.middleName,
    lastName: subscription.lastName,*/
    email: subscription.email,
    /*phone: subscription.phone,
    dietNotes: subscription.dietNotes,*/
  }

  return <>
    <Formik initialValues={form_values} onSubmit={onSubmit}>
      <Form>
        <Grid container>
          <FormField>
            <Field name="email" label="E-mail" component={FormikTextField}/>
          </FormField>
        </Grid>

        <Box sx={{ textAlign: 'right' }}>
          <Button onClick={onCancel}>Annuleren</Button>
          <Button type='submit' variant="contained">Opslaan</Button>
        </Box>
      </Form>
    </Formik>


  </>
}

export default EditSubscription
