import { TextField } from '@mui/material'
//import AdapterDateFns from '@mui/lab/AdapterDateFns'
//import LocalizationProvider from '@mui/lab/LocalizationProvider'
//import DatePicker from '@mui/lab/DatePicker'

//import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

import { nl } from 'date-fns/locale'

const FormikDatePicker = ({ field, form: { errors, touched, setFieldValue }, label }) => {
  return <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={nl}>
    <DatePicker
      {...field}
      mask='__-__-____'
      error={touched[field.name] && !!errors[field.name]}
      onChange={(newValue) => {
        setFieldValue(field.name, newValue)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          label={label}
          helperText={touched[field.name] && errors[field.name]}
        />
      )}
    />
  </LocalizationProvider>
}

export default FormikDatePicker
