import { useState } from 'react'
import { Box } from "@mui/system"
import { Delete, MoveToInbox, FileDownload, Person, Receipt, Check, Close, Send, Edit } from "@mui/icons-material"
import { Button, Table, TableBody, TableRow, TableCell, TableHead, Chip, Link, IconButton, Alert, Snackbar } from "@mui/material"
import { ContextMenuToggle, ContextMenuWrapper } from "../context-menu"
import SimpleModal from '../SimpleModal'
import MoveSubscription from './MoveSubscription'
import EditSubscription from './EditSubscription'
import Confirm from "../Confirm"
import SubscriptionsClient from "../../clients/Subscriptions"
import config from '../../config'
import authFetch from '../../authFetch'
import PaymentDetails from './PaymentDetails'
import { formatPrice } from '../../functions'
import Resend from '../../clients/Resend'

const ResendClient = new Resend()

const statuses = {
  init: {
    label: 'Nieuw',
    color: 'primary'
  },
  paid: {
    label: 'Betaald',
    color: 'success'
  },
  unpaid: {
    label: 'Nog niet betaald',
    color: 'warning'
  },
  reserved: {
    label: 'Gereserveerd',
    color: 'warning'
  },
}

const actions = {
  remove: {
    label: 'Verwijderen',
    icon: Delete
  },
  move: {
    label: 'Verplaatsen',
    icon: MoveToInbox
  },
  persons: {
    label: 'Aanpassen naar 1 persoon',
    icon: Person,
    when: (id, params) => params && params.persons > 1
  },
  editSubscription: {
    label: 'E-mailadres aanpassen',
    icon: Edit,
  },
  resendConfirmation: {
    label: 'Bevestigingsmail opnieuw verzenden',
    icon: Send,
  },
  resendMoved: {
    label: 'Verplaatsingsmail opnieuw verzenden',
    icon: Send,
  },
  resendReminder: {
    label: 'Herinneringsmail opnieuw verzenden',
    icon: Send,
  }
}

function click (node) {
  try {
    node.dispatchEvent(new MouseEvent('click'))
  } catch (e) {
    var evt = document.createEvent('MouseEvents')
    evt.initMouseEvent('click', true, true, window, 0, 0, 0, 80,
                        20, false, false, false, false, 0, null)
    node.dispatchEvent(evt)
  }
}

const subscriptionsClient = new SubscriptionsClient()

const Subscriptions = ({ subscriptions, refresh, id, date }) => {
  const [deletingSubscription, setDeletingSubscription] = useState(null)
  const [moveSubscription, setMoveSubscription] = useState(null)
  const [paymentDetails, setPaymentDetails] = useState(null)
  const [updating, setUpdating] = useState(null)
  const [editSubscription, setEditSubscription] = useState(null)

  const [alert, setAlert] = useState(null)

  const confirmDeleteItem = async () => {
    await subscriptionsClient.delete(deletingSubscription.id)
    setDeletingSubscription(null)
    refresh()
  }

  const changeNrOfPersons = async (subscriptionId) => {
    setUpdating(subscriptionId)
    await subscriptionsClient.update(subscriptionId, { nrOfPersons: 1 })
    refresh()
    setTimeout(() => {
      setUpdating(null)
    }, 1000)
  }

  const onContextAction = (reference, action) => {
    console.log('context actie', reference, action);

    if (action === 'move') {
      const subscription = subscriptions.find(sub => sub.id === reference)
      setMoveSubscription(subscription)
    } else if (action === 'remove') {
      const subscription = subscriptions.find(sub => sub.id === reference)
      setDeletingSubscription(subscription)
    } else if (action === 'persons') {
      changeNrOfPersons(reference)
    } else if (action === 'editSubscription') {
      const subscription = subscriptions.find(sub => sub.id === reference)
      setEditSubscription(subscription)
    } else if (action === 'resendConfirmation') {
      
      const subscription = subscriptions.find(sub => sub.id === reference)

      ResendClient.confirm(subscription.id);

      setAlert({
        severity: 'success',
        message: `Bevestigingsmail verzonden naar ${subscription.email}`
      })

    } else if (action === 'resendMoved') {

      const subscription = subscriptions.find(sub => sub.id === reference)
      ResendClient.moved(subscription.id);

          setAlert({
            severity: 'success',
            message: `Verplaatsingsmail verzonden naar ${subscription.email}`
          })

    } else if (action === 'resendReminder') {

      const subscription = subscriptions.find(sub => sub.id === reference)
      ResendClient.reminder(subscription.id);

          setAlert({
            severity: 'success',
            message: `Herinneringsmail verzonden naar ${subscription.email}`
          })

    }
  }

  const onMoveSubscription = () => {
    setMoveSubscription(null)
    // Refresh
    if (refresh) {
      refresh()
    }
  }

  const onEditSubscription = () => {
    setEditSubscription(null)
    // Refresh
    if (refresh) {
      refresh()
    }
  }

  const exportExcel = async () => {
    const response = await authFetch(
      `${config.serverUrl}/opendays/export/${id}`
    )

    const b64 = await response.text()

    const a = document.createElement('a')
    a.download = `Open-dag-${date.substring(0, 10)}.xlsx`
    a.rel = 'noopener'
    a.href = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + b64

    setTimeout(function () { URL.revokeObjectURL(a.href) }, 4E4) // 40s
    setTimeout(function () { click(a) }, 0)
  }

  const now = new Date()

  return <ContextMenuWrapper actions={actions} onAction={onContextAction}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Naam</TableCell>
          <TableCell>E-mail</TableCell>
          <TableCell>Telefoonnummer</TableCell>
          <TableCell align="center">Aantal personen</TableCell>
          <TableCell>Bedrag</TableCell>
          <TableCell>Allergieën</TableCell>
          <TableCell align="center">Opt-in</TableCell>
          <TableCell>Status</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {subscriptions.map(sub => {
          let status = sub.status
          if (sub.status === 'init' || sub.status === 'unpaid') {
            if (new Date(sub.expires) < now) {
              return null
            } else {
              status = 'reserved'
            }
          }
          return <TableRow key={`sub${sub.id}`}>
            <TableCell>{sub.firstName} {sub.middleName} {sub.lastName}</TableCell>
            <TableCell><Link href={`mailto:${sub.email}`}>{sub.email}</Link></TableCell>
            <TableCell>{sub.phone}</TableCell>
            <TableCell align="center">{updating === sub.id ? 'Bezig...' : sub.nrOfPersons}</TableCell>
            <TableCell>{updating === sub.id ? 'Bezig...' : formatPrice(sub.costs)}</TableCell>
            <TableCell>{sub.dietNotes}</TableCell>
            <TableCell align="center">{sub.optIn ? <Check fontSize="small" color="success"/> : <Close fontSize="small" color="error"/>}</TableCell>
            <TableCell padding="none" sx={{ whiteSpace: 'nowrap' }}>
              {statuses[status]
                ? <Chip label={statuses[status].label} color={statuses[status].color}/>
                : status
              }
              {sub.status === 'paid' &&
                <IconButton
                  sx={{ ml: 1 }}
                  title="Betaligsgegevens bekijken"
                  onClick={() => setPaymentDetails(sub.id)}
                >
                  <Receipt fontSize="small"/>
                </IconButton>
              }
            </TableCell>
            <TableCell align="right" padding="checkbox">
              <ContextMenuToggle reference={sub.id} persons={sub.nrOfPersons}/>
            </TableCell>
          </TableRow>
        })}
      </TableBody>
    </Table>

    {subscriptions.length > 0
      ? <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button variant="outlined" onClick={exportExcel} startIcon={<FileDownload/>}>
            Exporteren naar Excel
          </Button>
        </Box>
      : null
    }

    <SimpleModal
      open={!!moveSubscription}
      onClose={() => setMoveSubscription(null)}
      title="Aanmelding verplaatsen"
      width={1024}
    >
      {moveSubscription &&
        <MoveSubscription
          subscription={moveSubscription}
          onReady={onMoveSubscription}
          onCancel={() => setMoveSubscription(null)}
        />
      }
    </SimpleModal>

    <SimpleModal
      open={!!paymentDetails}
      onClose={() => setPaymentDetails(null)}
      title="Betalingsdetails"
    >
      {paymentDetails &&
        <PaymentDetails
          subscription={paymentDetails}
          onCancel={() => setPaymentDetails(null)}
        />
      }
    </SimpleModal>

    <SimpleModal open={!!editSubscription}
                 onClose={() => setEditSubscription(null)}
                 title="E-mailadres aanpassen"
                 width={1024}>
      <EditSubscription
        subscription={editSubscription}
        onCancel={() => setEditSubscription(null)}
        onReady={onEditSubscription}/>
    </SimpleModal>

    <Confirm
      open={!!deletingSubscription}
      onClose={() => setDeletingSubscription(null)}
      title={`Weet u zeker dat u de aanmelding van ${deletingSubscription?.firstName} ${deletingSubscription?.middleName} ${deletingSubscription?.lastName} wilt verwijderen?`}
      message={<Alert severity="warning">Let op! Dit kan niet meer ongedaan gemaakt worden!</Alert>}
      onOk={confirmDeleteItem}
      confirmCaption='Verwijderen'
      confirmColor='error'
    />

    <Snackbar open={!!alert} autoHideDuration={5000} onClose={() => setAlert(null)}>
      <Alert onClose={() => setAlert(null)} severity={alert?.severity} sx={{ width: '100%' }}>
        {alert?.message}
      </Alert>
    </Snackbar>

  </ContextMenuWrapper>
}

export default Subscriptions
