import { Box } from "@mui/system"
import { Typography, List, ListSubheader, ListItemButton, ListItemIcon, ListItemText, Collapse } from "@mui/material"
import { ExpandLess, ExpandMore } from "@mui/icons-material"
import { useQuery } from "react-query"
import { useEffect, useState } from "react"
import config from "../../config"
import Loader from "../../components/Loader"
import { formatPrice } from "../../functions"

const Payment = ({ values }) => {
  const [working, setWorking] = useState(false)
  const [openIndex, setOpenIndex] = useState(false)

  const { isLoading, data: paymentMethods } = useQuery('payment-methods', () => {
    return fetch(`${config.serverUrl}/payments/methods`)
      .then(res => res.json())
  })

  useEffect(() => {
    if (!isLoading && openIndex === false && paymentMethods?.length === 1) {
      setOpenIndex(0)
    }
  }, [isLoading, openIndex, paymentMethods?.length])

  const initializePayment = async (method, data) => {
    setWorking(true)

    const payload = {
      subscriptionId: values.subscription.id,
      amount: values.subscription.costs,
      method: method,
      data: data||null
    }

    const response = await fetch(`${config.serverUrl}/payments`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json'
      }
    })

    const payment = await response.json()

    if (payment && payment.redirectUrl) {
      window.location = payment.redirectUrl
    } else {
      setWorking(false)
    }
  }

  const handleMethodClick = (index) => {
    const method = paymentMethods[index]
    const hasDetails = method.issuers || method.key === 'creditcard'

    if (hasDetails) {
      if (index === openIndex) {
        setOpenIndex(-1)
      } else {
        setOpenIndex(index)
      }
    } else {
      // Select payment method
      initializePayment(method.key)
    }
  }

  const handleIssuerClick = (method, issuer) => {
    initializePayment(method, { issuer })
  }

  if (isLoading) {
    return <>Bezig met ophalen van data</>
  }

  if (working) {
    return <Loader/>
  }

  return <>
    <Box sx={{ p: 2 }}>
      <Typography variant="h6">Direct online betalen</Typography>
      <Typography variant="body2">Je reservering is pas definitief als je hebt betaald. Gelukkig kan je hieronder heel makkelijk online betalen.</Typography>
    </Box>
    <Box sx={{ p: 2 }}>
      <Typography variant="body1" style={{ fontWeight: 'bold' }}>Te betalen: {formatPrice(values.subscription.costs)}</Typography>
    </Box>
    <List
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          Kies een betaalmethode
        </ListSubheader>
      }
    >
      {
        paymentMethods.map((method, index) => <>
          <ListItemButton
            key={method.key}
            onClick={() => handleMethodClick(index)}
          >
            <ListItemIcon>
              <img src={`/images/${method.key}.png`} alt={method.label} width="24" />
            </ListItemIcon>
            <ListItemText
              primary={method.label}
              secondary={method.costs ? `+ ${formatPrice(method.costs)}` : "Geen extra kosten"}
            />
            {method.issuers || method.key === 'creditcard'
              ? openIndex === index ? <ExpandLess /> : <ExpandMore />
              : null
            }
          </ListItemButton>
          {method.issuers &&
            <Collapse
              in={openIndex === 0}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                {method.issuers.map(issuer => (
                  <ListItemButton
                    sx={{ pl: 4 }}
                    key={issuer.key}
                    onClick={() => handleIssuerClick(method.id, issuer.id)}
                  >
                    <ListItemIcon>
                      <img
                        src={`/images/${method.key}/${issuer.key}.png`}
                        alt={issuer.label}
                        width="24"
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={issuer.label}
                    />
                  </ListItemButton>
                ))}
              </List>
            </Collapse>
          }
        </>)
      }
    </List>
  </>
}

export default Payment
