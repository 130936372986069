import { IconButton, Toolbar, Typography } from "@mui/material"
import OpenDayOverview from '../../components/open-days/OpenDayOverview'
import { useParams, useNavigate } from 'react-router-dom'
import { ArrowBack } from "@mui/icons-material"
import { useQuery } from "react-query"
import OpendaysClient from "../../clients/Opendays"
import Loader from "../../components/Loader"

const opendaysClient = new OpendaysClient()

const OpenDayDuplicate = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const { isLoading, data: openDay } = useQuery(
    [ 'openday', id ],
    () => opendaysClient.get(id)
  )

  const goBack = () => {
    navigate('/admin')
  }

  const onReady = (openday) => {
    navigate(`/admin/opendays/${openday.id}`)
  }

  if (isLoading) {
    return <Loader/>
  }

  delete openDay.id

  const selectedItem = { ...openDay, editMode: true }

  return <>
    <Toolbar disableGutters>
      <IconButton onClick={goBack}>
        <ArrowBack/>
      </IconButton>
      <Typography variant="h6" sx={{ ml: 1 }}>
        Open dag dupliceren
      </Typography>
    </Toolbar>
    <OpenDayOverview {...selectedItem} onReady={onReady}/>
  </>
}

export default OpenDayDuplicate
