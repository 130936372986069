import config from "../config";

class Sessions {
  async refresh(session) {
    const response = await fetch(
      `${config.serverUrl}/sessions/refresh`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ token: session.token, refreshToken: session.refreshToken })
      }
    )
    const data = await response.json()
    return data
  }

  async get(token) {
    const response = await fetch(`${config.serverUrl}/sessions/${token}`)
    const data = await response.json()
    return data
  }

  async login(username, password) {
    const response = await fetch(
      `${config.serverUrl}/sessions/login`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ username, password })
      }
    )
    const data = await response.json()
    return data
  }

  async requestPasswordReset(username) {
    const response = await fetch(
      `${config.serverUrl}/sessions/request`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ username })
      }
    )
    const data = await response.json()
    return data
  }

  async getEditable(username, editKey) {
    const response = await fetch(
      `${config.serverUrl}/sessions/editable?data=${btoa(`${username}:${editKey}`)}`
    )
    const data = await response.json()
    return data
  }

  async resetPassword(username, editKey, password) {
    const response = await fetch(
      `${config.serverUrl}/sessions/password`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ data: btoa(`${username}:${editKey}:${password}`) })
      }
    )
    const data = await response.json()
    return data
  }
}

export default Sessions
