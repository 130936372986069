import { Checkbox, FormControlLabel, FormHelperText } from "@mui/material"

const FormikCheckbox = ({ field, form: { errors, touched, setFieldValue }, label }) => {
  const isError = touched[field.name] && !!errors[field.name]

  const onChange = (event) => {
    setFieldValue(field.name, event.target.checked)
  }

  return <>
    <FormControlLabel
      error={isError}
      control={<Checkbox error={isError} checked={field.value === true} onChange={onChange} name={field.name} />}
      label={label}
    />
    {isError && <FormHelperText error>{errors[field.name]}</FormHelperText>}
  </>
}

export default FormikCheckbox
