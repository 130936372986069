import { Box } from "@mui/system"
import { useEffect, useState } from "react"
import { Alert, Snackbar, TextField, Typography } from "@mui/material"
import Opendays from "../../clients/Opendays"
import RichText from "../RichText"
import InteractiveButton from "../InteractiveButton"

const opendaysClient = new Opendays()

const placeholders = {
  naam: 'Naam van de aanmelder',
  email: 'E-mailadres van de aanmelder',
  personen: 'Aantal personen van de aanmelding (alleen cijfer)',
  personen_tekst: 'Aantal personen van de aanmelding (cijfer + perso(o)n(en))',
  dieetwensen: 'Allergieën van de aanmelder',
  datum: 'Datum van de open dag',
  starttijd: 'Starttijd van de open dag',
  eindtijd: 'Eindtijd van de open dag',
  datum_aanmelding: 'Datum van de aanmelding'
}

const OpenDayTexts = (props) => {
  const [working, setWorking] = useState(false)
  const [confirmPageTitle, setConfirmPageTitle] = useState(props.confirmPageTitle)
  const [confirmPageBody, setConfirmPageBody] = useState(props.confirmPageBody)
  const [confirmMailSubject, setConfirmMailSubject] = useState(props.confirmMailSubject)
  const [confirmMailBody, setConfirmMailBody] = useState(props.confirmMailBody)
  const [reminderSubject, setReminderSubject] = useState(props.reminderSubject)
  const [reminderBody, setReminderBody] = useState(props.reminderBody)
  const [confirmMoveMailSubject, setConfirmMoveMailSubject] = useState(props.confirmMoveMailSubject)
  const [confirmMoveMailBody, setConfirmMoveMailBody] = useState(props.confirmMoveMailBody)
  const [alert, setAlert] = useState(null)

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 100)
  }, [])

  const onSubmitClick = async () => {
    setWorking(true)

    const values = {
      confirmPageTitle: confirmPageTitle || '',
      confirmPageBody: confirmPageBody || '',
      confirmMailSubject: confirmMailSubject || '',
      confirmMailBody: confirmMailBody || '',
      reminderSubject: reminderSubject || '',
      reminderBody: reminderBody || '',
      confirmMoveMailSubject: confirmMoveMailSubject || '',
      confirmMoveMailBody: confirmMoveMailBody || ''
    }

    const data = await opendaysClient.update(props.id, values)

    setAlert({
      severity: 'success',
      message: 'De wijzigingen zijn opgeslagen'
    })

    if (props.onReady) {
      props.onReady(data)
    } else {
      props.refresh()
    }

    setWorking(false)
  }

  return <>
    <Box sx={{ p: 2 }}>
      <Typography variant="h5" sx={{ mb: 1 }}>
        Bevestigingspagina
      </Typography>
      <TextField
        label="Titel"
        value={confirmPageTitle}
        onChange={event => setConfirmPageTitle(event.target.value)}
        fullWidth
      />
      <RichText
        value={confirmPageBody}
        onChange={v => setConfirmPageBody(v)}
        style={{ marginTop: 8 }}
        placeholders={placeholders}
      />
    </Box>

    <Box sx={{ p: 2 }}>
      <Typography variant="h5" sx={{ mb: 1 }}>
        Bevestigingsmail
      </Typography>
      <TextField
        label="Onderwerp"
        value={confirmMailSubject}
        onChange={event => setConfirmMailSubject(event.target.value)}
        fullWidth
      />
      <RichText
        value={confirmMailBody}
        onChange={v => setConfirmMailBody(v)}
        style={{ marginTop: 8 }}
        placeholders={placeholders}
      />
    </Box>

    <Box sx={{ p: 2 }}>
      <Typography variant="h5" sx={{ mb: 1 }}>
        Bevestigingsmail verplaatsing
      </Typography>
      <TextField
        label="Onderwerp"
        value={confirmMoveMailSubject}
        onChange={event => setConfirmMoveMailSubject(event.target.value)}
        fullWidth
      />
      <RichText
        value={confirmMoveMailBody}
        onChange={v => setConfirmMoveMailBody(v)}
        style={{ marginTop: 8 }}
        placeholders={placeholders}
      />
    </Box>

    <Box sx={{ p: 2 }}>
      <Typography variant="h5" sx={{ mb: 1 }}>
        Herinneringsmail
      </Typography>
      <TextField
        label="Onderwerp"
        value={reminderSubject}
        onChange={event => setReminderSubject(event.target.value)}
        fullWidth
      />
      <RichText
        value={reminderBody}
        onChange={v => setReminderBody(v)}
        style={{ marginTop: 8 }}
        placeholders={placeholders}
      />
    </Box>
    <Box sx={{ p: 2 }}>
      <InteractiveButton working={working} variant="contained" onClick={onSubmitClick}>Opslaan</InteractiveButton>
    </Box>

    <Snackbar open={!!alert} autoHideDuration={5000} onClose={() => setAlert(null)}>
      <Alert onClose={() => setAlert(null)} severity={alert?.severity} sx={{ width: '100%' }}>
        {alert?.message}
      </Alert>
    </Snackbar>
  </>
}

export default OpenDayTexts
