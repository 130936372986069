import { TextField } from "@mui/material"

const FormikTextField = ({ field, form: { errors, touched }, ...props }) => {
  const isError = touched[field.name] && !!errors[field.name]
  const helperText = isError && errors[field.name]

  return <TextField
    {...field}
    error={isError}
    helperText={helperText}
    fullWidth
    {...props}
  />
}

export default FormikTextField
