import { Step, StepLabel, Stepper } from "@mui/material"
import { useRef, useState } from "react"

const Stepped = ({ steps, showStepper, onReady }) => {
  const [activeStep, setActiveStep] = useState(0)
  const values = useRef({})

  const Component = activeStep < steps.length
    ? steps[activeStep].component
    : null

  const onPrevious = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1)
    }
  }

  const onNext = (newValues) => {
    if (newValues) {
      values.current = { ...values.current, ...newValues }
    }

    let step = activeStep

    while (step < steps.length - 1) {
      step++
      if (!steps[step].skipWhen || !steps[step].skipWhen(values.current)) {
        break;
      }
    }

    if (step < steps.length) {
      setActiveStep(step)
    } else if (onReady) {
      onReady(values.current)
    }
  }

  return <>
    {showStepper &&
      <Stepper
        activeStep={activeStep}
      >
        {steps.map((step, index) => (
          <Step key={`step${index}`}>
            <StepLabel>{step.label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    }
    {Component &&
      <Component
        onPrevious={activeStep > 0 ? onPrevious : null}
        onNext={onNext}
        values={values.current}
      />
    }
  </>
}

export default Stepped
