import { Box } from "@mui/system"
import { Table, TableBody, TableRow, TableCell, Alert, Button } from "@mui/material"
import { dateFromString, formatPrice, humanReadableDate } from "../../functions"
import LinearProgressWithLabel from "../LinearProgressWithLabel"
import { Edit } from "@mui/icons-material"
import OpenDayEdit from "./OpenDayEdit"
import { useState } from "react"

const OpenDayOverview = (props) => {
  const [editMode, setEditMode] = useState(props.editMode || false)

  const onEditReady = (values) => {
    if (props.onReady) {
      props.onReady(values)
    } else {
      setEditMode(false)
      if (props.refresh) {
        props.refresh()
      }
    }
  }

  return <Box sx={{ mt: props.id ? 2 : 0 }}>
    {editMode
      ? <>
          <OpenDayEdit {...props} onReady={onEditReady}/>
        </>
      : <>
          {props.subscriptionCount > props.capacity &&
            <Alert severity="error">
              Er zijn meer aanmeldingen dan dat er plekken zijn.
            </Alert>
          }

          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Datum</TableCell>
                <TableCell>{humanReadableDate(dateFromString(props.date))}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Tijd</TableCell>
                <TableCell>{props.startTime.substring(0, 5)} - {props.endTime.substring(0, 5)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Aantal plaatsen</TableCell>
                <TableCell>{props.capacity}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Kosten per persoon</TableCell>
                <TableCell>{formatPrice(props.costs)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Opmerkingen</TableCell>
                <TableCell>{props.description}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Aanmelden mogelijk</TableCell>
                <TableCell>
                  {humanReadableDate(new Date(props.openFrom))} t/m {humanReadableDate(new Date(props.openTo))}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Aantal aanmeldingen</TableCell>
                <TableCell>{props.subscriptionCount}{props.pendingSubscriptionCount ? ` + ${props.pendingSubscriptionCount} gereserveerd` : ''}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Beschikbaarheid</TableCell>
                <TableCell>
                  <LinearProgressWithLabel
                    variant="determinate"
                    value={Math.round(100 * props.subscriptionCount / props.capacity)}
                    valueBuffer={Math.round(100 * (props.subscriptionCount + props.pendingSubscriptionCount) / props.capacity)}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>

          <Box sx={{ mt: 2 }}>
            <Button startIcon={<Edit/>} onClick={() => setEditMode(true)}>
              Bewerken
            </Button>
          </Box>
        </>
    }

  </Box>
}

export default OpenDayOverview
