import { Alert, Button, Link as MuiLink, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { Field, Form, Formik } from "formik"
import { Link } from "react-router-dom"
import FormikTextField from "../../components/form/FormikTextField"
import * as Yup from "yup"
import { useState } from "react"
import SessionsClient from "../../clients/Sessions"

const sessionsClient = new SessionsClient()

const validationSchema = Yup.object().shape({
  username: Yup.string().required('Gebruikersnaam is verplicht'),
})

const RequestPassword = () => {
  const [message, setMessage] = useState(null)

  const onSubmit = async (values, { setSubmitting }) => {
    const result = await sessionsClient.requestPasswordReset(values.username)

    if (!result?.success) {
      switch (result?.error) {
        case 'invalid_username':
          setMessage({
            severity: 'success',
            message: 'Als je gebruikersnaam of e-mailadres bij ons bekend is, krijg je dadelijk een e-mail met een link om je wachtwoord te herstellen'
          })
          break

        default:
          setMessage({ severity: 'error', message: result?.error || 'Er is een onbekende fout opgetreden' })
          break
      }
    } else {
      setMessage({
        severity: 'success',
        message: 'Als je gebruikersnaam of e-mailadres bij ons bekend is, krijg je dadelijk een e-mail met een link om je wachtwoord te herstellen'
      })
    }

    setSubmitting(false)
  }

  return <Formik
    initialValues={{ username: '' }}
    validationSchema={validationSchema}
    onSubmit={onSubmit}
  >
    <Form>
      <Typography variant="h4">
        Wachtwoord vergeten
      </Typography>
      {message && <Alert severity={message.severity}>{message.message}</Alert>}
      <Box sx={{ mt: 2 }}>
        <Typography variant="paragraph">
          Vul hier je e-mailadres of gebruikersnaam in. Je krijgt van ons een mailtje met een link om een nieuw wachtwoord in te stellen.
        </Typography>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Field
          component={FormikTextField}
          name="username"
          label="Gebruikersnaam of e-mailadres"
          fullWidth
        />
      </Box>
      <Box sx={{ mt: 2 }}>
        <Button type="submit" variant="contained">Verstuur</Button>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Link to="/login" component={MuiLink}>Terug naar inloggen</Link>
      </Box>
    </Form>
  </Formik>
}

export default RequestPassword
