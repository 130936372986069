import { Link, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { Button } from '@mui/material'
import { Formik, Form, Field } from 'formik'
import { dateFromString, formatPrice, humanReadableDate } from '../../functions'
import FormikTextField from '../form/FormikTextField'
import FormikNumber from '../form/FormikNumber'
import * as Yup from 'yup'
import FormikRadio from '../form/FormikRadio'
import useSettings from "../../hooks/useSettings"

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('Voornaam is verplicht'),
  middleName: Yup.string(),
  lastName: Yup.string().required('Achternaam is verplicht'),
  email: Yup.string().email('Dit is geen geldig e-mailadres').required('E-mailadres is verplicht'),
  phone: Yup.string(),
  nrOfPersons: Yup.number().required('Aantal personen is verplicht'),
  optIn: Yup.string().oneOf([ 'yes', 'no' ], 'Geef aan of je op de hoogte gehouden wil worden')
})

const DetailsForm = ({ pickedDay, onPrevious, onSubmit }) => {
  const settings = useSettings()

  const initialValues = {
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    phone: '',
    nrOfPersons: 1,
    optIn: ''
  }

  let date = null

  if (pickedDay) {
    date = dateFromString(pickedDay.date)
  }

  const handleSubmit = (values, { setSubmitting }) => {
    if (onSubmit) {
      onSubmit({ ...values, optIn: values.optIn === 'yes' })
    }
    setSubmitting(false)
  }

  const remainingSpots = pickedDay.capacity - pickedDay.subscriptionCount

  return <Formik
    initialValues={initialValues}
    validationSchema={validationSchema}
    onSubmit={handleSubmit}
  >
    <Form>
      {date && <Typography variant="h4">
        Inschrijven voor {humanReadableDate(date)}
      </Typography>}
      <Box sx={{ mt: 2 }}>
        <Typography variant="paragraph">
          <div dangerouslySetInnerHTML={{ __html: settings.homeSubscribe }}></div>
        </Typography>
      </Box>
      <Box sx={{ mt: 4 }}>
        <Field
          component={FormikTextField}
          name="firstName"
          label="Roepnaam leerling/student"
          fullWidth
        />
      </Box>
      <Box sx={{ mt: 4 }}>
        <Field
          component={FormikTextField}
          name="middleName"
          label="Tussenvoegsels leerling/student"
          fullWidth
        />
      </Box>
      <Box sx={{ mt: 4 }}>
        <Field
          component={FormikTextField}
          name="lastName"
          label="Achternaam leerling/student"
          fullWidth
        />
      </Box>
      <Box sx={{ mt: 2 }}>
        <Field
          component={FormikTextField}
          type="email"
          name="email"
          label="E-mailadres"
          fullWidth
        />
      </Box>
      <Box sx={{ mt: 2 }}>
        <Field
          component={FormikTextField}
          name="phone"
          label="Telefoonnummer (optioneel)"
          fullWidth
        />
      </Box>
      <Box sx={{ mt: 2 }}>
        <Field
          component={FormikNumber}
          name="nrOfPersons"
          label="Aantal personen"
          description={remainingSpots < 2 ? "Er is nog maar 1 plek beschikbaar" : "Je kunt maximaal voor 2 personen aanmelden"}
          min={1}
          max={Math.min(2, remainingSpots)}
          fullWidth
        />
      </Box>
      <Box sx={{ mt: 2 }}>
        <Field
          component={FormikTextField}
          multiline
          name="dietNotes"
          label="Allergieën"
          fullWidth
        />
      </Box>
      <Box sx={{ mt: 2 }}>
        <Field
          component={FormikRadio}
          name="optIn"
          label='Wil je dat we jou via e-mail en social media op de hoogte houden?'
          options={{ yes: 'Ja', no: 'Nee' }}
        />
      </Box>
      <Box sx={{ mt: 2 }}>
        <Typography variant="body2">
          We helpen je graag met het maken van de juiste studiekeuze.
          Om dit te doen, registreren we een aantal gegevens van je.
          Ben je jonger dan 16, vraag dan eerst toestemming aan je ouders/verzorgers hiervoor.
          Je ontvangt dan (maximaal 2x per week) informatie over evenementen, informatie over studiekeuze en nieuws van NHL Stenden Hogeschool.
          Voor meer informatie, zie onze <Link href="https://www.nhlstenden.com/privacyverklaring" target="_blank">privacy statement</Link>.
        </Typography>
      </Box>
      <Box sx={{ mt: 2, display: 'flex' }}>
        {onPrevious && <>
          <Button variant="outlined" onClick={onPrevious}>Vorige</Button>
          <Box sx={{ flexGrow: 1 }}/>
        </>}
        <Button type="submit" variant="contained">Volgende</Button>
      </Box>
    </Form>
  </Formik>
}

export default DetailsForm
