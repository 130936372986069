import { Button, Radio, RadioGroup, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { useState } from "react"
import { dateFromString, humanReadableDate } from "../../functions"
import { useQuery } from "react-query"
import LinearProgressWithLabel from "../LinearProgressWithLabel"
import Opendays from "../../clients/Opendays"
import SubscriptionsClient from "../../clients/Subscriptions"

const opendaysClient = new Opendays()
const subscriptionsClient = new SubscriptionsClient()

const SelectDate = ({ subscription, onReady, onCancel }) => {
  const [selected, setSelected] = useState(null)

  const { isLoading, data } = useQuery('opendays', opendaysClient.getAll, { retry: false })

  const openDays = data

  const onSelectDate = (event, newValue) => {
    setSelected(event.target.value)
  }

  const onSubmit = async () => {
    if (!selected) return
    const opendayId = parseInt(selected)

    await subscriptionsClient.update(subscription.id, { opendayId })

    if (onReady) {
      onReady()
    }
  }

  return <>
    {isLoading
      ? <Typography variant="paragraph">Bezig met ophalen van data</Typography>
      : <RadioGroup
          value={selected}
          onChange={onSelectDate}
        >
          <Typography variant="paragraph">Kies een van onderstaande data</Typography>
          <Table sx={{ mb: 2 }}>
            <TableHead>
              <TableRow>
                <TableCell colSpan={2}>Datum</TableCell>
                <TableCell>Tijd</TableCell>
                <TableCell align="center">Plaatsen</TableCell>
                <TableCell align="center">Aanmeldingen</TableCell>
                <TableCell align="center">Gereserveerd</TableCell>
                <TableCell>Bezetting</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {openDays.map(openDay => (
                subscription.opendayId !== openDay.id && dateFromString(openDay.date) > new Date()
                  ? <TableRow
                      key={openDay.id}
                    >
                      <TableCell
                        padding="checkbox"
                      >
                        <Radio
                          value={openDay.id}
                        />
                      </TableCell>
                      <TableCell>
                        {humanReadableDate(dateFromString(openDay.date))}
                      </TableCell>
                      <TableCell>
                        {openDay.startTime.substring(0, 5)} tot {openDay.endTime.substring(0, 5)}
                      </TableCell>
                      <TableCell align="center">
                        {openDay.capacity}
                      </TableCell>
                      <TableCell align="center">
                        {openDay.subscriptionCount}
                      </TableCell>
                      <TableCell align="center">
                        {openDay.pendingSubscriptionCount}
                      </TableCell>
                      <TableCell>
                        <LinearProgressWithLabel
                          variant="buffer"
                          value={Math.round(100 * openDay.subscriptionCount / openDay.capacity)}
                          valueBuffer={Math.round(100 * (openDay.subscriptionCount + openDay.pendingSubscriptionCount) / openDay.capacity)}
                        />
                      </TableCell>
                    </TableRow>
                  : null
              ))}
            </TableBody>
          </Table>
        </RadioGroup>
    }

    <Box sx={{ textAlign: 'right' }}>
      <Button onClick={onCancel}>Annuleren</Button>
      <Box sx={{ display: 'inline-block', width: 16 }}/>
      <Button variant="contained" onClick={onSubmit} disabled={!selected}>Verplaatsen</Button>
    </Box>
  </>
}

export default SelectDate
