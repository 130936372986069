const priceFormat = new Intl.NumberFormat("nl", {
  style: 'currency',
  currency: 'EUR',
  currencyDisplay: 'symbol',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})

const dateFromString = (string) => {
  if (!string || typeof string !== 'string') return new Date()
  const [ year, month, day ] = string.substring(0, 10).split('-').map(value => parseInt(value))
  return new Date(year, month - 1, day)
}

const humanReadableDate = (date) => {
  if (!(date instanceof Date)) {
    date = new Date(date)
  }

  return date.toLocaleDateString(
    'nl',
    {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    }
  )
}

const formatPrice = (price) => {
  return priceFormat.format(parseFloat(price))
}

export {
    dateFromString,
    humanReadableDate,
    formatPrice
}
