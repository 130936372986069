import SettingsClient from './clients/Settings'

const settingsClient = new SettingsClient()

const settings = {
  all: null,

  fetch: async () => {
    settings.all = await settingsClient.getAll()
  },

  get: async (key, def) => {
    if (!settings.all) await settings.fetch()
    return settings.all[key] || def
  },

  getAll: async (key, def) => {
    if (!settings.all) await settings.fetch()
    return settings.all
  },

  invalidate: () => {
    settings.all = null
  }
}

export default settings
