import { Box } from "@mui/system"
import { Tab, Tabs } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { useState } from "react"

const Tabbed = ({ tabs, props, children, hideTabs }) => {
  const [tabsValue, setTabsValue] = useState(0)
  const path = window?.location?.pathname
  const activeTab = tabs.findIndex(tab => tab.path === path)
  const navigate = useNavigate()

  const tabsComponents = tabs.length > 0 && !!tabs[0].component
  const Component = tabsComponents && tabs[tabsValue].component
  const _props = props || {}

  const onChange = (event, newValue) => {
    if (tabs[newValue] && tabs[newValue].path) {
      navigate(tabs[newValue].path)
    } else {
      setTabsValue(newValue)
    }
  }

  return <>
    {!hideTabs &&
      <Box sx={{ borderBottom: '1px solid', borderBottomColor: 'divider' }}>
        <Tabs
          value={activeTab > -1 ? activeTab : tabsValue}
          onChange={onChange}
        >
          {tabs.map((tab, index) => {
            return <Tab key={`tab${index}`} label={tab.label}/>
          })}
        </Tabs>
      </Box>
    }
    {tabsComponents
      ? (Component && <Component {..._props}/>)
      : children
    }
  </>
}

export default Tabbed
