import config from "../config";
import authFetch from "../authFetch";

const objectFilterKeys = (obj, keys) => {
  const result = {}

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i]
    if ((key in obj) && obj[key] !== null) {
      result[key] = obj[key]
    }
  }

  return result
}

class Opendays {
  async getAll() {
    const response = await authFetch(`${config.serverUrl}/opendays`)
    const data = await response.json()
    return data
  }

  async getAvailable() {
    const response = await fetch(`${config.serverUrl}/opendays/available`)
    const data = await response.json()
    return data
  }

  async get(id) {
    const response = await authFetch(`${config.serverUrl}/opendays/${id}`)
    const data = await response.json()
    return data
  }

  async create(values, duplicateOf) {
    if (duplicateOf) {
      const dup = objectFilterKeys(duplicateOf, [
        'capacity',
        'capacityNotified',
        'confirmMailBody',
        'confirmMailSubject',
        'confirmMoveMailBody',
        'confirmMoveMailSubject',
        'confirmPageBody',
        'confirmPageTitle',
        'costs',
        'date',
        'endTime',
        'isOpen',
        'locationId',
        'openFrom',
        'openTo',
        'reminderBody',
        'reminderSubject',
        'startTime'
      ])
      values = { ...dup, ...values }
    }

    const response = await authFetch(
      `${config.serverUrl}/opendays`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(values)
      }
    )
    const data = await response.json()
    return data
  }

  async update(id, values) {
    const response = await authFetch(
      `${config.serverUrl}/opendays/${id}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(values)
      }
    )
    const data = await response.json()
    return data
  }

  async delete(id) {
    const response = await authFetch(
      `${config.serverUrl}/opendays/${id}`,
      {
        method: 'DELETE'
      }
    )
    const data = await response.json()
    return data
  }

  async sendWaitingListUpdates(ids, mailTexts) {
    mailTexts = mailTexts || {}
    const response = await authFetch(
      `${config.serverUrl}/opendays/update-waiting-list`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ opendayIds: ids, ...mailTexts })
      }
    )
    const data = await response.json()
    return data
  }

  async determineWaitingListUpdates(ids) {
    const response = await authFetch(
      `${config.serverUrl}/opendays/waiting-list`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ opendayIds: ids })
      }
    )
    const data = await response.json()
    return data
  }
}

export default Opendays
