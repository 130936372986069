import config from "../config";
import authFetch from "../authFetch";

class Subscriptions {
  async getAll() {
    const response = await authFetch(`${config.serverUrl}/subscriptions`)
    const data = await response.json()
    return data
  }

  async get(id) {
    const response = await fetch(`${config.serverUrl}/subscriptions/${id}`)
    const data = await response.json()
    return data
  }

  async getPayment(id) {
    const response = await fetch(`${config.serverUrl}/subscriptions/payment/${id}`)
    const data = await response.json()
    return data
  }

  async getPaymentDetails(id) {
    const response = await authFetch(`${config.serverUrl}/subscriptions/payments/${id}`)
    const data = await response.json()
    return data
  }

  async getUnpaid(id) {
    const response = await authFetch(`${config.serverUrl}/subscriptions/unpaid`)
    const data = await response.json()
    return data
  }

  async create(values) {
    const response = await fetch(
      `${config.serverUrl}/subscriptions`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(values)
      }
    )
    const data = await response.json()
    return data
  }

  async createQueue(values) {
    const response = await fetch(
      `${config.serverUrl}/queue`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(values)
      }
    )
    const data = await response.json()
    return data
  }

  async getQueue() {
    const response = await authFetch(`${config.serverUrl}/queue`)
    const data = await response.json()
    return data
  }

  async update(id, values) {
    const response = await authFetch(
      `${config.serverUrl}/subscriptions/${id}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(values)
      }
    )
    const data = await response.json()
    return data
  }

  async delete(id) {
    const response = await authFetch(
      `${config.serverUrl}/subscriptions/${id}`,
      {
        method: 'DELETE'
      }
    )
    const data = await response.json()
    return data
  }
}

export default Subscriptions
