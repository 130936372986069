const messages = {
    DataTable: {
      rowsPerPage: 'Rijen per pagina',
      rows: 'rijen',
      totalRows: 'Totaal aantal rijen',
      of: 'van'
    }
  }

  export default messages
