import { Alert, Link as MuiLink, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { Field, Form, Formik } from "formik"
import { Link } from "react-router-dom"
import FormikTextField from "../../components/form/FormikTextField"
import * as Yup from "yup"
import { useState } from "react"
import SessionsClient from "../../clients/Sessions"
import { useQuery } from "react-query"
import Loader from "../../components/Loader"
import InteractiveButton from "../../components/InteractiveButton"

const sessionsClient = new SessionsClient()

const validationSchema = Yup.object().shape({
  password: Yup.string().required('Wachtwoord is verplicht'),
  passwordConfirm: Yup.string()
    .test('passwords-match', 'De wachtwoorden komen niet overeen', function(value){
      return this.parent.password === value
    })
})

const ResetPassword = () => {
  const [message, setMessage] = useState(null)
  const [working, setWorking] = useState(false)

  const query = window?.location?.search ? window.location.search.match(/u=([^&]+)&k=([^&]+)/) : null
  const username = query ? query[1] : ''
  const editKey = query ? query[2] : ''

  const { isLoading, data } = useQuery(
    [ 'editable', username ],
    () => sessionsClient.getEditable(username, editKey),
    { retry: false, refetchOnWindowFocus: false }
  )

  const onSubmit = async (values, { setSubmitting }) => {
    setWorking(true)

    const result = await sessionsClient.resetPassword(username, editKey, values.password)

    if (!result?.success) {
        setMessage({ severity: 'error', message: result?.error || 'Er is een onbekende fout opgetreden' })
    } else {
      setMessage({
        severity: 'success',
        message: 'Je wachtwoord is opnieuw ingesteld. Je kunt nu inloggen met je nieuwe wachtwoord'
      })
    }

    setSubmitting(false)
    setWorking(false)
  }

  if (isLoading) {
    return <Loader/>
  }

  if (!data?.success) {
    return <>
      <Typography variant="h4">
        Wachtwoord opnieuw instellen
      </Typography>
      <Alert severity="error">De link die je hebt gebruikt is ongeldig of verlopen</Alert>
      <Box sx={{ mt: 2 }}>
        <MuiLink to="/login/password" component={Link}>Nieuwe link aanvragen</MuiLink>
      </Box>
      <Box>
        <MuiLink to="/login" component={Link}>Terug naar inloggen</MuiLink>
      </Box>
    </>
  }

  return <Formik
    initialValues={{ username: '' }}
    validationSchema={validationSchema}
    onSubmit={onSubmit}
  >
    <Form>
      <Typography variant="h4">
        Wachtwoord opnieuw instellen
      </Typography>
      {message
        ? <Alert severity={message?.severity}>{message?.message}</Alert>
        : <>
            <Box sx={{ mt: 2 }}>
              <Typography variant="paragraph">
                Vul hieronder je nieuwe wachtwoord in en daarna nog eens ter controle
              </Typography>
            </Box>
            <Box sx={{ mt: 2 }}>
              <Field
                component={FormikTextField}
                type="password"
                name="password"
                label="Nieuw wachtwoord"
                fullWidth
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <Field
                component={FormikTextField}
                type="password"
                name="passwordConfirm"
                label="Bevestig je nieuwe wachtwoord"
                fullWidth
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <InteractiveButton type="submit" variant="contained" working={working}>Verstuur</InteractiveButton>
            </Box>
          </>
      }
      <Box sx={{ mt: 2 }}>
        <Link to="/login" component={MuiLink}>Terug naar inloggen</Link>
      </Box>
    </Form>
  </Formik>
}

export default ResetPassword
