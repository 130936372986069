import { IconButton, Menu, MenuItem, ListItemIcon, ListItemText, Divider } from "@mui/material"
import { MoreVert } from "@mui/icons-material"
import { createContext, useContext, useState } from "react"

const ContextMenuContext = createContext()

const ContextMenuWrapper = ({ children, actions, onAction }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [reference, setReference] = useState(null)
  const [props, setProps] = useState(null)

  const openContextMenu = (event, ref, contextProps) => {
    setReference(ref)
    setProps(contextProps)
    setAnchorEl(event.currentTarget)
  }

  const closeContextMenu = (event) => {
    setAnchorEl(null)
  }

  const onClick = (action) => {
    onAction(reference, action)
    closeContextMenu()
  }

  return <ContextMenuContext.Provider value={{ openContextMenu }}>
    {children}
    <Menu
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={closeContextMenu}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
    >
      {actions && Object.keys(actions).map(action => {
        if (actions[action] === 'divider') {
          return <Divider key={action}/>
        }

        if (actions[action].when && !actions[action].when(reference, props)) {
          return null
        }

        const Icon = actions[action].icon

        return <MenuItem key={action} onClick={() => onClick(action)}>
          <ListItemIcon>
            {Icon && <Icon fontSize="small" />}
          </ListItemIcon>
          <ListItemText>{actions[action].label}</ListItemText>
        </MenuItem>
      })}
    </Menu>
  </ContextMenuContext.Provider>
}

const ContextMenuToggle = ({ reference, ...props }) => {
  const { openContextMenu } = useContext(ContextMenuContext)

  return <IconButton fontSize="small" onClick={event => openContextMenu(event, reference, props)}>
    <MoreVert/>
  </IconButton>
}


export {
  ContextMenuWrapper,
  ContextMenuToggle
}
