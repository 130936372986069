import { Typography } from "@mui/material"
import { Box } from "@mui/system"
import { Fragment } from "react"
// import { useEffect } from "react"
import ReactQuill from "react-quill"
// import { useQuill } from "react-quilljs"

const RichText = ({ placeholders, value, onChange, style }) => {
  // const { quill, quillRef } = useQuill()

  // useEffect(() => {
  //   if (quill) {
  //     quill.on('text-change', () => {
  //       if (onChange) {
  //         onChange(quill.root.innerHTML); // Get innerHTML using quill
  //       }
  //     });

  //     quill.clipboard.dangerouslyPasteHTML(value);
  //     quill.blur();
  //   }
  // }, [quill])

  return <>
    <ReactQuill value={value} onChange={onChange} style={style} modules={{ clipboard: { matchVisual: false } }}/>
    {placeholders
      ? <>
          <Box sx={{ py: 1 }}><Typography variant="body2">De beschikbare codes zijn:</Typography></Box>
          <Box sx={{ p: 2, backgroundColor: 'divider' }}>
            <Typography variant="body2" component='div'>
              <dl>
                {Object.keys(placeholders).map((key) => (<Fragment key={key}>
                  <dt>#{key}#</dt>
                  <dd>{placeholders[key]}</dd>
                </Fragment>))}
              </dl>
            </Typography>
          </Box>
        </>
      : null
    }
  </>
}

export default RichText
