import { useEffect, useState } from "react"
import settings from "../settings"

const useSettings = () => {
  const [values, setValues] = useState(null)

  useEffect(() => {
    const fetchSettings = async () => {
      const result = await settings.getAll()
      setValues(result)
    }

    if (!values) {
      fetchSettings()
    }
  })

  return values || {}
}

export default useSettings
