import { ArrowDropDown } from "@mui/icons-material"
import { AppBar, Button, Menu, MenuItem, Toolbar, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import auth from "../auth"

const Header = () => {
  const [accountAnchorEl, setAccountAnchorEl] = useState(null)
  const navigate = useNavigate()

  const logout = () => {
    auth.logout()
    navigate('/login')
  }

  const goToFrontend = () => {
    window?.open(`${window.location.protocol}//${window.location.host}`)
  }

  // return <Box sx={{ px: 2, py: 2, display: 'flex', alignItems: 'center' }}>
  return <>
    <AppBar sx={{ boxShadow: 'none' }}>
      <Toolbar>
        {/*<Box>
          <img src="/logo-nhlstenden-white.png" style={{ width: 64 }} alt="NHLStenden"/>
        </Box>*/}
        <Box sx={{ ml: 0 }}>
          <Typography variant="h5" sx={{ color: 'white' }}>
            Inschrijving Open Dagen
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1 }}/>
        <Box sx={{ ml: 2 }}>
          <Button
            endIcon={<ArrowDropDown/>}
            variant="contained"
            onClick={(event) => setAccountAnchorEl(event.currentTarget)}
          >
            {auth.getUser()}
          </Button>
          <Menu
            anchorEl={accountAnchorEl}
            open={!!accountAnchorEl}
            onClose={() => setAccountAnchorEl(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <MenuItem onClick={goToFrontend}>Naar de inschrijving</MenuItem>
            <MenuItem onClick={logout}>Uitloggen</MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
    <Toolbar/>
  </>
}


export default Header
