import './App.css'
import { createTheme, ThemeProvider } from "@mui/material/styles"
import { QueryClient, QueryClientProvider } from "react-query"
import { Route, BrowserRouter, Routes } from "react-router-dom"
import { Helmet, HelmetProvider } from "react-helmet-async"
import Admin from "./layouts/Admin"
import OpenDayDetail from "./pages/admin/OpenDayDetail"
import OpenDayCreate from "./pages/admin/OpenDayCreate"
import OpenDays from "./pages/admin/OpenDays"
import OpenPayments from "./pages/admin/OpenPayments"
import Frontend from "./layouts/Frontend"
import OpenDayDuplicate from "./pages/admin/OpenDayDuplicate"
import ConfirmPayment from "./pages/frontend/ConfirmPayment"
import Subscribe from "./pages/frontend/Subscribe"
// import Guard from "./components/Guard"
import DirectPayment from './pages/frontend/DirectPayment'
import AdminPage from './pages/admin/AdminPage'
import WaitingList from './pages/admin/WaitingList'
import Login from './pages/login/Login'
import RequestPassword from './pages/login/RequestPassword'
import ResetPassword from './pages/login/ResetPassword'
import SettingsPage from './pages/admin/SettingsPage'

const theme = createTheme({
  palette: {
    primary: {
      main: '#005aa7',
      light: '#0078ba',
      dark: '#005196',
      contrastText: '#fff'
    },
    secondary: {
      main: '#E61E5B',
      light: '#FF2571',
      dark: '#99005b',
      contrastText: '#fff'
    }
  },
  typography: {
    fontFamily: [
      'Cera Pro',
      'Arial',
      'Helvetica',
      'sans-serif'
    ].join(', '),
    paragraph: {
      lineHeight: 1.5
    },
    h4: {
      fontWeight: 'bold',
      color: '#00294b'
    },
    h5: {
      fontWeight: 'bold',
      color: '#00294b'
    },
  },
  shape: {
    borderRadius: 0,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {

          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none'
          }
        }
      }
    }
  }
})
const queryClient = new QueryClient()

const App = () => {
  return <ThemeProvider theme={theme}>
    <HelmetProvider>
      <Helmet>
        <meta name="theme-color" content={theme.palette.primary.main}/>
      </Helmet>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routes>
            <Route path="/">
              <Route index element={<Frontend><Subscribe/></Frontend>}/>
              <Route path="login/password" element={<Frontend><RequestPassword/></Frontend>}/>
              <Route path="login/reset" element={<Frontend><ResetPassword/></Frontend>}/>
              <Route path="login" element={<Frontend><Login/></Frontend>}/>
              <Route path="payment/ok/:id" element={<Frontend><ConfirmPayment/></Frontend>}/>
              <Route path="payment/:subid" element={<Frontend><DirectPayment/></Frontend>}/>
              <Route path="admin" element={<Admin/>}>
                <Route path="past" element={<AdminPage><OpenDays past/></AdminPage>}/>
                <Route path="settings" element={<AdminPage><SettingsPage/></AdminPage>}/>
                <Route path="create-openday" element={<OpenDayCreate/>}/>
                <Route path="duplicate-openday/:id" element={<OpenDayDuplicate/>}/>
                <Route path="opendays/:id" element={<OpenDayDetail/>}/>
                <Route path="payments" element={<AdminPage><OpenPayments/></AdminPage>}/>
                <Route path="waiting-list" element={<AdminPage><WaitingList/></AdminPage>}/>
                <Route index element={<AdminPage><OpenDays/></AdminPage>}/>
              </Route>
            </Route>
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
    </HelmetProvider>
  </ThemeProvider>
}

export default App;
