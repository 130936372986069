import SessionsClient from './clients/Sessions'

const _ls = localStorage?.getItem('Auth.Session')
let stored = _ls ? JSON.parse(_ls) : null
const sessionsClient = new SessionsClient()

const auth = {
  blocked: false,

  isLoggedIn: () => {
    return stored && stored.expires > Date.now()
  },

  canRefresh: () => {
    return !!stored
  },

  refresh: async () => {
    let session = null

    session = await sessionsClient.refresh(stored)

    if (!session || session.error) {
      console.log(session?.error)
      if (session?.error === 'no_token') {
        auth.blocked = true
      }
      stored = null
      return false
    }

    stored = { ...session }
    stored.expires = new Date(stored.expires).getTime()
    localStorage?.setItem('Auth.Session', JSON.stringify(stored))

    return true
  },

  getUser: () => {
    return stored?.username
  },

  getRole: () => {
    return stored?.userRole
  },

  getToken: () => {
    return stored?.token
  },

  login: async (username, password) => {
    if (auth.blocked) return

    const result = await sessionsClient.login(username, password)

    if (!result?.success) {
      stored = null
      throw result?.error || 'error'
    }

    stored = { ...result }
    stored.expires = new Date(stored.expires).getTime()
    localStorage?.setItem('Auth.Session', JSON.stringify(stored))
  },

  logout: () => {
    stored = null
    localStorage?.removeItem('Auth.Session')
  }
}

export default auth
