import SettingsClient from "../../clients/Settings"
import { useQuery, useQueryClient } from "react-query"
import Loader from "../../components/Loader"
import { Field, Form, Formik } from "formik"
import { Alert, Snackbar, Typography } from "@mui/material"
import { Box } from "@mui/system"
import InteractiveButton from "../../components/InteractiveButton"
import { useEffect, useState } from "react"
import FormikTextField from "../../components/form/FormikTextField"
import FormikRichText from "../../components/form/FormikRichText"
import settings from "../../settings"

const settingsClient = new SettingsClient()

const placeholders = {
  naam: 'Naam van de aanmelder',
  email: 'E-mailadres van de aanmelder',
  datum_aanmelding: 'Datum van de aanmelding',
  opendagen: 'De lijst van nieuwe open dagen',
  url: 'De URL naar de inschrijfpagina: [tekst in de link](#url#)'
}

const SettingsPage = () => {
  const [working, setWorking] = useState(false)
  const [alert, setAlert] = useState(null)
  const queryClient = useQueryClient()

  const emptyValues = {
    serviceEmail: '',
    homeTitle: '',
    homeBody: '',
    homeBodyNoDays: '',
    waitingListSubject: '',
    waitingListBody: '',
    waitingListButton: '',
    homeWaitingList: '',
    homeSubscribe: '',
  }

  const { isLoading, data } = useQuery('settings', settingsClient.getAllWithPrivate)

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 100)
  }, [])

  const handleSubmit = async (values, { setSubmitting }) => {
    setWorking(true)

    const data = {
      homeTitle: values.homeTitle,
      homeBody: values.homeBody,
      homeBodyNoDays: values.homeBodyNoDays,
      homeWaitingList: values.homeWaitingList,
      homeSubscribe: values.homeSubscribe,
      serviceEmail: {
        value: values.serviceEmail,
        private: true
      },
      waitingListSubject: values.waitingListSubject,
      waitingListBody: values.waitingListBody,
      waitingListButton: values.waitingListButton,
    }

    await settingsClient.update(data)

    setWorking(false)
    setSubmitting(false)

    settings.invalidate()
    queryClient.invalidateQueries('settings')

    setAlert({ severity: "success", message: "De wijzigingen zijn opgeslagen" })
  }

  if (isLoading) {
    return <Loader/>
  }
  const initialValues = data
    ? { ...emptyValues, ...data }
    : emptyValues

  return <>
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      <Form>
        <Typography variant="h4" sx={{ mt: 4 }}>
          Instellingen
        </Typography>
        <Box sx={{ mt: 4 }}>
          <Field
            component={FormikTextField}
            name="serviceEmail"
            label="E-mailadres voor meldingen"
            fullWidth
          />
        </Box>

        <Typography variant="h5" sx={{ mt: 4 }}>
          Homepage
        </Typography>
        <Box sx={{ mt: 2 }}>
          <Field
            component={FormikTextField}
            name="homeTitle"
            label="Titel"
            fullWidth
          />
        </Box>
        <Box sx={{ mt: 4 }}>
          <Field
            component={FormikRichText}
            name="homeBody"
            label="Tekst"
            fullWidth
          />
        </Box>
        <Box sx={{ mt: 4 }}>
          <Field
            component={FormikRichText}
            name="homeBodyNoDays"
            label="Tekst als er geen open dagen zijn"
            fullWidth
          />
        </Box>

        <Box sx={{ mt: 4 }}>
          <Field
            component={FormikRichText}
            name="homeWaitingList"
            label="Tekst bij aanmeldformulier wachtlijst"
            fullWidth
          />
        </Box>

        <Box sx={{ mt: 4 }}>
          <Field
            component={FormikRichText}
            name="homeSubscribe"
            label="Tekst bij aanmeldformulier opendag"
            fullWidth
          />
        </Box>

        <Typography variant="h5" sx={{ mt: 4 }}>
          Standaardmail geïnteresseerden op de wachtlijst
        </Typography>
        <Box sx={{ mt: 2 }}>
          <Field
            component={FormikTextField}
            name="waitingListSubject"
            label="Onderwerp"
            fullWidth
          />
        </Box>
        <Box sx={{ mt: 4 }}>
          <Field
            component={FormikRichText}
            name="waitingListBody"
            label="Tekst"
            fullWidth
            placeholders={placeholders}
          />
        </Box>
        <Box sx={{ mt: 4 }}>
          <Field
            component={FormikTextField}
            name="waitingListButton"
            label="Tekst in knop"
            fullWidth
          />
        </Box>


        <Box sx={{ mt: 2, display: 'flex' }}>
          <InteractiveButton type="submit" variant="contained" working={working}>Opslaan</InteractiveButton>
        </Box>
      </Form>
    </Formik>

    <Snackbar open={!!alert} autoHideDuration={5000} onClose={() => setAlert(null)}>
      <Alert onClose={() => setAlert(null)} severity={alert?.severity} sx={{ width: '100%' }}>
        {alert?.message}
      </Alert>
    </Snackbar>
  </>
}

export default SettingsPage
