import { Box } from "@mui/system"
import { FormLabel, IconButton, FormHelperText } from "@mui/material"
import { Add as AddIcon, Remove as RemoveIcon } from "@mui/icons-material"
import { useState } from "react"

const FormikNumber = ({ field, form: { errors, touched, setFieldValue }, label, min, max, description }) => {
  const [value, setValue] = useState(field.value || min || 0)

  const decrease = () => {
    const _min = min || 0
    if (value > _min) {
      setFieldValue(field.name, value - 1)
      setValue(value - 1)
    }
  }

  const increase = () => {
    const _max = max || 100
    if (value < _max) {
      setFieldValue(field.name, value + 1)
      setValue(value + 1)
    }
  }

  const isError = touched[field.name] && !!errors[field.name]

  return <>
    <FormLabel>{label}</FormLabel>
    {description && <FormHelperText>{description}</FormHelperText>}
    <Box sx={{ display: 'flex', alignItems: 'center', color: isError ? 'red' : 'inherit' }}>
      <IconButton onClick={decrease} size="small">
        <RemoveIcon size="small"/>
      </IconButton>
      <Box sx={{ px: 1 }}>{value}</Box>
      <IconButton onClick={increase} size="small">
        <AddIcon size="small"/>
      </IconButton>
    </Box>
    {isError && <FormHelperText error>{errors[field.name]}</FormHelperText>}
  </>
}

export default FormikNumber
