import { Box } from "@mui/system"
import { IconButton, Toolbar, Typography } from "@mui/material"
import Tabbed from "../../components/Tabbed"
import OpenDayOverview from '../../components/open-days/OpenDayOverview'
import OpenDayTexts from '../../components/open-days/OpenDayTexts'
import Subscriptions from '../../components/open-days/Subscriptions'
import { useParams, useNavigate } from 'react-router-dom'
import { useQuery } from "react-query"
import { dateFromString, humanReadableDate } from "../../functions"
import { ArrowBack } from "@mui/icons-material"
import Opendays from "../../clients/Opendays"
import Loader from "../../components/Loader"

const opendaysClient = new Opendays()

const StyledBadge = ({ badgeContent, color, children }) => {
  return <Box style={{ whiteSpace: 'nowrap' }}>
    {children}
    {badgeContent ? <Box sx={{ backgroundColor: `${color||'primary'}.main`, color: 'white', display: 'inline-block', ml: 1, padding: '0 5px', minWidth: 10, height: 20, borderRadius: '10px', lineHeight: '20px', textAlign: 'center' }}>
      {badgeContent}
    </Box> : null}
  </Box>
}

const OpenDayDetail = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const goBack = () => {
    navigate('/admin')
  }

  const { isLoading, data, refetch } = useQuery([ 'openday', id ], () => opendaysClient.get(id))

  const refresh = () => {
    refetch()
  }

  const selectedItem = data
  const now = new Date()
  const subscriptionCount = selectedItem?.subscriptions?.reduce((carry, sub) => {
    if ((sub.status === 'init' || sub.status === 'unpaid') && new Date(sub.expires) < now) {
      return carry
    }
    return carry + sub.nrOfPersons
  }, 0) || 0

  const tabs = [
    {
      label: 'Details',
      component: OpenDayOverview
    },
    {
      label: 'Teksten',
      component: OpenDayTexts
    },
    {
      label: <StyledBadge badgeContent={subscriptionCount} color="primary">Aanmeldingen</StyledBadge>,
      component: Subscriptions
    }
  ]

  return <>
    {isLoading && <Loader/>}
    {!isLoading && (!data || data.error) && <Box>Open dag niet gevonden</Box>}
    {!isLoading && selectedItem && <>
      <Toolbar disableGutters>
        <IconButton onClick={goBack}>
          <ArrowBack/>
        </IconButton>
        <Typography variant="h6" sx={{ ml: 1 }}>
          Open dag {humanReadableDate(dateFromString(selectedItem.date))}
        </Typography>
      </Toolbar>
      <Tabbed
        tabs={tabs}
        props={{ ...selectedItem, refresh }}
        hideTabs={!selectedItem?.id}
      />
    </>}
  </>
}

export default OpenDayDetail
