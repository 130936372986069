import { Box } from "@mui/system"
import { IconButton, Modal, Typography } from "@mui/material"
import { Close } from "@mui/icons-material"

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxHeight: 'calc(100% - 32px)',
  overflowY: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  outline: 'none'
}

const SimpleModal = ({ open, title, onClose, closeOnBgClick, children, width }) => {
  return <Modal
      open={open}
      onClose={closeOnBgClick ? onClose : null}
    >
      <Box sx={{ ...modalStyle, width: width || 800 }}>
        <Box
          sx={{
            px: 3,
            py: 2,
            borderBottom: '1px solid',
            borderBottomColor: 'divider',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Typography variant="h6">{title}</Typography>
          <IconButton size="small" onClick={onClose}>
            <Close fontSize="small"/>
          </IconButton>
        </Box>
        <Box
          sx={{
            px: 3,
            py: 2,
          }}
        >
          {children}
        </Box>
      </Box>
    </Modal>
}

export default SimpleModal
