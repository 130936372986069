import { Box } from "@mui/system"
import { Typography, Link } from "@mui/material"
import { humanReadableDate, dateFromString } from "../../functions"

const fillBody = (body, subscription, openday) => {
  const result = body
    .replace(/#naam#/ig, `${subscription.firstName}${subscription.middleName ? ' ' + subscription.middleName : ''}${subscription.lastName ? ' ' + subscription.lastName : ''}`)
    .replace(/#email#/ig, subscription.email)
    .replace(/#personen#/ig, subscription.nrOfPersons)
    .replace(/#personen_tekst#/ig, `${subscription.nrOfPersons === 1 ? '1 persoon' : `${subscription.nrOfPersons} personen`}`)
    .replace(/#dieetwensen#/ig, subscription.dietNotes)
    .replace(/#datum#/ig, humanReadableDate(dateFromString(openday.date)))
    .replace(/#starttijd#/ig, openday.startTime?.substring(0, 5))
    .replace(/#eindtijd#/ig, openday.endTime?.substring(0, 5))
    .replace(/#datum_aanmelding#/ig, humanReadableDate(subscription.meta.created))
    .replace(/<p>/g, '<p style="margin:0;">')

  return result
}

const Confirm = ({ values }) => {
  return <>
    {values.openDay
      ? <>
          <Typography variant="h4">{fillBody(values.openDay.confirmPageTitle, values, values.openDay)}</Typography>
          <Box sx={{mt:1}}>
            <div dangerouslySetInnerHTML={{ __html: fillBody(values.openDay.confirmPageBody, values, values.openDay) }}></div>
          </Box>
        </>
      : <>
          <Typography variant="h4">We houden je op de hoogte!</Typography>
          <Box sx={{mt:1}}>
            <Typography variant="paragraph">Op het moment dat er nieuwe data beschikbaar zijn, zul je van ons een mail ontvangen.</Typography>
          </Box>
          <Box sx={{mt:1}}>
            <Typography variant="paragraph"><Link onClick={() => window.location.reload()} sx={{ cursor: 'pointer' }}>Terug naar het overzicht</Link></Typography>
          </Box>
        </>
    }
  </>
}

export default Confirm
