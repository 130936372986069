import config from "../config";
import authFetch from "../authFetch";

class Settings {
  async getAll() {
    const response = await fetch(`${config.serverUrl}/settings`)
    const data = await response.json()
    return data
  }

  async getAllWithPrivate() {
    const response = await authFetch(`${config.serverUrl}/settings/private`)
    const data = await response.json()
    return data
  }

  async update(values) {
    const response = await authFetch(
      `${config.serverUrl}/settings`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(values)
      }
    )
    const data = await response.json()
    return data
  }
}

export default Settings
