import { Box } from "@mui/system"
import { Link, Typography } from "@mui/material"
import { useQuery } from "react-query"
import { useParams } from "react-router"
import SubscriptionsClient from "../../clients/Subscriptions"
import Loader from "../../components/Loader"
import Confirm from "./Confirm"
import { Link as RouterLink } from "react-router-dom"

const subscriptionsClient = new SubscriptionsClient()

const ConfirmPayment = () => {
  const params = useParams()
  const subscriptionId = params.id

  const { isLoading, data: subscription } = useQuery(
    [ 'subscription', subscriptionId ],
    () => subscriptionsClient.getPayment(subscriptionId)
  )

  if (isLoading) {
    return <Loader/>
  }

  if (subscription?.payment?.status !== 'open' && subscription?.payment?.status !== 'paid') {
    return <>
        <Typography variant="h4">Er iets fout gegaan met het betalen</Typography>
        <Box sx={{mt:1}}>

        {(subscription?.payment?.status === 'expired' && subscription?.payment?.status === 'canceled') ? 
          <Typography variant="paragraph">Probeer je later nog een keer in te schrijven voor de open dag.</Typography>

          :

          <Typography variant="paragraph">
            De betaling is nog niet verwerkt of mislukt.
            <br/>
            Zodra de betaling succesvol is verwerkt, ontvang je een bevestiging in je mailbox.
          </Typography>
        }

          
        </Box>
        <Box sx={{mt:1}}>
          <Typography variant="paragraph"><Link component={RouterLink} to="/">Terug naar het overzicht</Link></Typography>
        </Box>
      </>
  }

  return <Confirm values={{ ...subscription, openDay: subscription.openday }}/>
}

export default ConfirmPayment
