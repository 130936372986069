import { Alert, Button, Link as MuiLink, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { Field, Form, Formik } from "formik"
import { Link, useNavigate } from "react-router-dom"
import FormikTextField from "../../components/form/FormikTextField"
import * as Yup from "yup"
import auth from "../../auth"
import { useState } from "react"

const validationSchema = Yup.object().shape({
  username: Yup.string().required('Gebruikersnaam is verplicht'),
  password: Yup.string().required('Wachtwoord is verplicht'),
})

const Login = () => {
  const [error, setError] = useState(null)
  const navigate = useNavigate()

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      await auth.login(values.username, values.password)
      navigate('/admin')
    } catch (err) {
      switch (err) {
        case 'invalid_credentials':
          setError('Deze combinatie van gebruikersnaam en wachtwoord is niet correct')
          break

        default:
          setError(err.toString())
          break
      }
    }

    setSubmitting(false)
  }

  return <Formik
    initialValues={{ username: '', password: '' }}
    validationSchema={validationSchema}
    onSubmit={onSubmit}
  >
    <Form>
      <Typography variant="h4">
        Inloggen
      </Typography>
      {error && <Alert severity="error">{error}</Alert>}
      <Box sx={{ mt: 2 }}>
        <Typography variant="paragraph">
          Log hier in met je gebruikersnaam en wachtwoord
        </Typography>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Field
          component={FormikTextField}
          name="username"
          label="Gebruikersnaam"
          fullWidth
        />
      </Box>
      <Box sx={{ mt: 2 }}>
        <Field
          component={FormikTextField}
          type="password"
          name="password"
          label="Wachtwoord"
          fullWidth
        />
      </Box>
      <Box sx={{ mt: 2 }}>
        <Button type="submit" variant="contained">Log in</Button>
      </Box>
      <Box sx={{ mt: 2 }}>
        <MuiLink to="/login/password" component={Link}>Wachtwoord vergeten?</MuiLink>
      </Box>
    </Form>
  </Formik>
}

export default Login
