import { useQuery } from "react-query"
import { useParams } from "react-router"
import Payment from "./Payment"
import SubscriptionsClient from "../../clients/Subscriptions"
import Loader from "../../components/Loader"

const subscriptionsClient = new SubscriptionsClient()

const DirectPayment = () => {
  const { subid } = useParams()

  const { isLoading, data: subscription } = useQuery(
    [ 'subscription', subid ],
    () => subscriptionsClient.get(subid)
  )

  if (isLoading) {
    return <Loader/>
  }

  return <Payment values={{ subscription }} />
}

export default DirectPayment
