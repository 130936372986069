const useLocale = () => {
  const lang = (document.documentElement.getAttribute('lang') || 'en').substring(0, 2).toLowerCase()
  const messages = require(`../locale/${lang}`).default

  return {
    translate: (message, section) => {
      if (section) {
        return messages[section] ? messages[section][message] || message : message
      }
      return messages[message] || message
    }
  }
}

export default useLocale
