import { Chip, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import { useQuery } from "react-query"
import SubscriptionsClient from "../../clients/Subscriptions"
import { formatPrice, humanReadableDate } from "../../functions"
import Loader from "../Loader"

const subscriptionsClient = new SubscriptionsClient()

const statuses = {
  init: {
    label: 'Betaling niet gestart',
    color: 'primary'
  },
  paid: {
    label: 'Betaald',
    color: 'success'
  },
  unpaid: {
    label: 'Nog niet betaald',
    color: 'warning'
  },
  open: {
    label: 'Open',
    color: 'primary'
  },
  canceled: {
    label: 'Geannuleerd',
    color: 'error'
  },
  expired: {
    label: 'Verlopen',
    color: 'error'
  },
  failed: {
    label: 'Mislukt',
    color: 'error'
  },
  authorized: {
    label: 'Geautoriseerd',
    color: 'secondary'
  },
  pending: {
    label: 'Pending',
    color: 'secondary'
  },
}

const PaymentDetails = ({ subscription }) => {
  const { isLoading, data } = useQuery(
    [ 'payment-details', subscription ],
    () => subscriptionsClient.getPaymentDetails(subscription)
  )

  if (isLoading || !data) {
    return <Loader/>
  }

  return <Table>
    <TableHead>
      <TableRow>
        <TableCell>Datum</TableCell>
        <TableCell>Rekeninghouder</TableCell>
        <TableCell>Rekeningnummer</TableCell>
        <TableCell>Bedrag</TableCell>
        <TableCell>Status</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {data.map(payment => (
        <TableRow key={payment.id}>
          <TableCell>{humanReadableDate(payment.meta.created)}</TableCell>
          <TableCell>{payment.details?.consumerName}</TableCell>
          <TableCell>{payment.details?.consumerAccount}</TableCell>
          <TableCell>{formatPrice(payment.amount)}</TableCell>
          <TableCell>
            {statuses[payment.status]
              ? <Chip label={statuses[payment.status].label} color={statuses[payment.status].color}/>
              : payment.status
            }
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
}

export default PaymentDetails
