import { Button, List, ListItemButton, ListItemText, Popover } from "@mui/material"
import ArrowDropDown from "@mui/icons-material/ArrowDropDown"
import { useState } from "react"

const DropdownButton = ({ options, value, onChange, ...props }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const selectOption = (option) => {
    setAnchorEl(null)
    if (onChange) {
      onChange(option)
    }
  }

  const open = !!anchorEl
  const id = open ? 'account-popover' : undefined

  return <>
    <Button
      {...props}
      endIcon={<ArrowDropDown/>}
      aria-describedby={id}
      onClick={handleClick}
    >
      {options instanceof Array ? value : (value && options[value] ? options[value] : value)}
    </Button>
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
    >
      <List dense>
        {options instanceof Array
          ? options.map((option, index) => (
              <ListItemButton key={index} selected={value === option} onClick={() => selectOption(option)}>
                <ListItemText>
                  {option}
                </ListItemText>
              </ListItemButton>
            ))
          : Object.keys(options).map(key => (
              <ListItemButton key={key} selected={value === key} onClick={() => selectOption(key)}>
                <ListItemText>
                  {options[key]}
                </ListItemText>
              </ListItemButton>
            ))
        }
      </List>
    </Popover>
  </>
}

export default DropdownButton
