import { Box } from "@mui/system"
import { Alert, Button, Link as MuiLink, Menu, MenuItem, Snackbar, ListItemIcon, ListItemText, Divider } from "@mui/material"
import { Warning as WarningIcon, Edit as EditIcon, ContentCopy as ContentCopyIcon, Delete as DeleteIcon, Check as CheckIcon, Block as BlockIcon, FileDownload, HourglassBottom, History, DoneAll, Payments } from "@mui/icons-material"
import LinearProgressWithLabel from "../../components/LinearProgressWithLabel"
import { Link } from "react-router-dom"
import { dateFromString, humanReadableDate } from "../../functions"
import { useQuery } from "react-query"
import { useNavigate } from "react-router-dom"
import { useState, useRef } from "react"
import Confirm from "../../components/Confirm"
import Opendays from "../../clients/Opendays"
import Loader from "../../components/Loader"
import config from "../../config"
import authFetch from "../../authFetch"
import SendReminders from "../../components/open-days/SendReminders"
import SimpleModal from "../../components/SimpleModal"
import DataTable from "../../components/DataTable"
import ExportPayments from "../../components/open-days/ExportPayments"

const actions = {
  edit: {
    label: 'Bewerken',
    icon: EditIcon
  },
  duplicate: {
    label: 'Dupliceren',
    icon: ContentCopyIcon
  },
  open: {
    label: 'Openen voor inschrijving',
    icon: CheckIcon,
    when: (reference, props) => !props?.isOpen
  },
  close: {
    label: 'Sluiten voor inschrijving',
    icon: BlockIcon,
    when: (reference, props) => props?.isOpen
  },
  divider: 'divider',
  delete: {
    label: 'Verwijderen',
    icon: DeleteIcon
  },
}

function click (node) {
  try {
    node.dispatchEvent(new MouseEvent('click'))
  } catch (e) {
    var evt = document.createEvent('MouseEvents')
    evt.initMouseEvent('click', true, true, window, 0, 0, 0, 80,
                        20, false, false, false, false, 0, null)
    node.dispatchEvent(evt)
  }
}

const opendaysClient = new Opendays()

const XOR = (a, b) => (a && !b) || (!a && b)

const columns = [
  {
    field: 'date',
    headerName: 'Datum en tijd',
    renderCell: params => {
      const date = dateFromString(params.date)
      return <>
        <MuiLink to={`/admin/opendays/${params.id}`} component={Link}>
          {humanReadableDate(date)}
        </MuiLink>
        <Box>{params.startTime.substring(0, 5)} - {params.endTime.substring(0, 5)}</Box>
      </>
    }
  },
  {
    field: 'capacity',
    headerName: 'Plaatsen',
    align: 'center'
  },
  {
    field: 'subscriptionCount',
    headerName: 'Aanmeldingen',
    align: 'center'
  },
  {
    field: 'pendingSubscriptionCount',
    headerName: 'Gereserveerd',
    align: 'center'
  },
  {
    field: 'otherSubscriptionCount',
    headerName: 'Niet betaald',
    align: 'center'
  },
  {
    field: 'occupation',
    headerName: 'Bezetting',
    width: 180,
    renderCell: params => {
      return <LinearProgressWithLabel
        variant="buffer"
        value={Math.round(100 * params.subscriptionCount / params.capacity)}
        valueBuffer={Math.round(100 * (params.subscriptionCount + params.pendingSubscriptionCount) / params.capacity)}
      />
    }
  },
  {
    field: 'occupationWarning',
    headerName: '',
    sortable: false,
    renderCell: params => (
      params.subscriptionCount > params.capacity &&
        <WarningIcon color="error" fontSize="small"/>
    )
  },
  {
    field: 'isOpen',
    headerName: '',
    sortable: false,
    renderCell: params => (
      params.isUpdating
        ? <HourglassBottom fontSize="small"/>
        : (params.isOpen ? <CheckIcon fontSize="small"/> : null)
    )
  }
]

const bulkActions = {
  sendUpdates: { label: 'Updates sturen naar geïnteresseerden' }
}

const OpenDays = ({ past }) => {
  const [alert, setAlert] = useState(null)
  const [deletingOpenDay, setDeletingOpenDay] = useState(null)
  const [exportMenuAnchor, setExportMenuAnchor] = useState(null)
  const [exportingPayments, setExportingPayments] = useState(false)
  const [, setUpdating] = useState(null)
  const [remindIds, setRemindIds] = useState(null)
  const clearBulk = useRef(null)

  const navigate = useNavigate()

  const { isLoading, data, refetch } = useQuery('opendays', opendaysClient.getAll, { retry: false })

  const now = new Date()
  const openDays = (!isLoading && (data instanceof Array)) ? data.filter(day => !XOR(past, new Date(day.date) < now)) : []

  const closeConfirm = () => {
    setDeletingOpenDay(null)
  }

  const confirmDeleteItem = async () => {
    await opendaysClient.delete(deletingOpenDay.id)
    setDeletingOpenDay(null)
    refetch()
  }

  const editItem = (reference) => {
    navigate(`/admin/opendays/${reference}`)
  }

  const duplicateItem = (reference) => {
    navigate(`/admin/duplicate-openday/${reference}`)
  }

  const deleteItem = (reference) => {
    setDeletingOpenDay(openDays.find(openDay => openDay.id === parseInt(reference)))
  }

  const openItem = async (reference) => {
    openDays.find(day => day.id === reference).isUpdating = true
    setUpdating(true)
    await opendaysClient.update(reference, { isOpen: true })
    await refetch()
    setUpdating(false)
  }

  const closeItem = async (reference) => {
    openDays.find(day => day.id === reference).isUpdating = true
    setUpdating(true)
    await opendaysClient.update(reference, { isOpen: false })
    await refetch()
    setUpdating(false)
  }

  const onRemindersSent = () => {
    if (clearBulk.current) {
      clearBulk.current()
      clearBulk.current = null
    }
    setAlert({ severity: 'success', message: 'De updates zijn verstuurd' })
    setRemindIds(null)
  }

  const sendUpdates = async (event, references, clrBulk) => {
    clearBulk.current = clrBulk
    setRemindIds(references)
  }

  const onBulkAction = async (action, event, references, clrBulk) => {
    if (action === 'sendUpdates') {
      sendUpdates(event, references, clrBulk)
    }
  }

  const onExportMenuClose = () => {
    setExportMenuAnchor(null)
  }

  const exportExcel = async (what) => {
    const url = what === 'payments'
      ? `${config.serverUrl}/payments/export`
      : `${config.serverUrl}/opendays/export/${what}`

    const response = await authFetch(url)

    const b64 = await response.text()

    const a = document.createElement('a')
    a.download = what === 'payments' ? 'Betalingen.xlsx' : 'Export.xlsx'
    a.rel = 'noopener'
    a.href = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + b64

    setTimeout(function () { URL.revokeObjectURL(a.href) }, 4E4) // 40s
    setTimeout(function () { click(a) }, 0)
  }

  const exportPastOpendays = () => {
    exportExcel('past')
    onExportMenuClose()
  }

  const exportAllOpendays = () => {
    exportExcel('all')
    onExportMenuClose()
  }

  const exportPayments = () => {
    // exportExcel('payments')
    onExportMenuClose()
    setExportingPayments(true)
  }

  const onContextAction = (reference, action) => {
    switch (action) {
      case 'edit':
        editItem(reference)
        break

      case 'duplicate':
        duplicateItem(reference)
        break

      case 'delete':
        deleteItem(reference)
        break

      case 'open':
        openItem(reference)
        break

      case 'close':
        closeItem(reference)
        break

      default:
        console.warn(`Unkown action: ${action}`)
        break
    }
  }

  if (isLoading) {
    return <Loader/>
  }

  return <>
    <Box sx={{ py: 2 }}>
      <DataTable
        columns={columns}
        rows={openDays}
        contextActions={actions}
        contextParams={params => ({ isOpen: params.isOpen })}
        onContextAction={onContextAction}
        checkboxSelection={true}
        bulkActions={bulkActions}
        onBulkAction={onBulkAction}
        defaultSort={{ field: 'date', dir: 1 }}
      />
    </Box>

    <Box sx={{ py: 2, display: 'flex' }}>
      <Button variant="contained" component={Link} to="/admin/create-openday">
        Nieuwe open dag
      </Button>
      <Box sx={{ flexGrow: 1 }}/>
      <Box>
        <Button variant="outlined" onClick={(e) => setExportMenuAnchor(e.currentTarget)} startIcon={<FileDownload/>}>
          Exporteren naar Excel
        </Button>
        <Menu
          anchorEl={exportMenuAnchor}
          open={!!exportMenuAnchor}
          onClose={onExportMenuClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {past && <MenuItem onClick={exportPastOpendays}>
            <ListItemIcon><History fontSize="small"/></ListItemIcon>
            <ListItemText>Exporteer afgelopen open dagen</ListItemText>
          </MenuItem>}
          {!past && <MenuItem onClick={exportAllOpendays}>
            <ListItemIcon><DoneAll fontSize="small"/></ListItemIcon>
            <ListItemText>Exporteer alle open dagen</ListItemText>
          </MenuItem>}
          <Divider/>
          <MenuItem onClick={exportPayments}>
            <ListItemIcon><Payments fontSize="small"/></ListItemIcon>
            <ListItemText>Exporteer betalingen</ListItemText>
          </MenuItem>
        </Menu>
      </Box>
    </Box>

    <SimpleModal
      open={!!remindIds}
      onClose={() => setRemindIds(null)}
      title="Updates versturen naar geïnteresseerden"
      width={1024}
    >
      {remindIds &&
        <SendReminders
          opendayIds={remindIds}
          onReady={onRemindersSent}
          onCancel={() => setRemindIds(null)}
        />
      }
    </SimpleModal>

    <SimpleModal
      open={exportingPayments}
      onClose={() => setExportingPayments(false)}
      title="Betalingen exporteren naar Excel"
    >
      {exportingPayments &&
        <ExportPayments
          onCancel={() => setExportingPayments(false)}
        />
      }
    </SimpleModal>

    <Confirm
      open={!!deletingOpenDay}
      onClose={closeConfirm}
      title='Weet u zeker dat u deze open dag wilt verwijderen?'
      message={<>U verwijdert hiermee ook alle inschrijvingen voor de open dag. <Alert severity="warning" sx={{ mt: 2 }}>Let op! Dit kan niet ongedaan gemaakt worden!</Alert></>}
      onOk={confirmDeleteItem}
      confirmCaption='Verwijderen'
      confirmColor='error'
    />

    <Snackbar open={!!alert} autoHideDuration={5000} onClose={() => setAlert(null)}>
      <Alert onClose={() => setAlert(null)} severity={alert?.severity} sx={{ width: '100%' }}>
        {alert?.message}
      </Alert>
    </Snackbar>
  </>
}

export default OpenDays
