import { Box } from "@mui/system"
import { Button, Checkbox, IconButton, Table, TableBody, TableCell, TableFooter, TableHead, TableRow } from "@mui/material"
import { ChevronLeft, ChevronRight, ArrowUpward, ArrowDownward } from "@mui/icons-material"
// import { makeStyles } from "@mui/styles"
import { useState } from "react"
import DropdownButton from "./DropdownButton"
import useLocale from "../hooks/useLocale"
import { ContextMenuToggle, ContextMenuWrapper } from "./context-menu"

// const useStyles = makeStyles({
//   headerCell: {
//     position: 'relative',
//     '& .MuiIconButton-root': {
//       opacity: 0,
//       marginLeft: 8,
//       position: 'absolute',
//       top: 12,
//       right: 0
//     },
//     '&:hover .MuiIconButton-root': {
//       opacity: 1
//     }
//   },
//   headerCellSorted: {
//     '& .MuiIconButton-root': {
//       opacity: 1
//     }
//   }
// })

const DataTable = ({ columns, rows, checkboxSelection, onRowSelect, pageSize, rowsPerPageOptions,
  onRowClick, contextActions, contextParams, onContextAction, bulkActions, onBulkAction, defaultSort }) => {
  const [sort, setSort] = useState(defaultSort)
  const [limit, setLimit] = useState(pageSize || 10)
  const [page, setPage] = useState(1)
  const [checked, setChecked] = useState([])

  const pages = Math.ceil(rows?.length / limit)
  const numColumns = columns.length + (checkboxSelection ? 1 : 0) + (contextActions ? 1 : 0)

  const locale = useLocale()

  const valueGetters = {}

  const fillChecked = (value) => {
    const checked = []
    for (let i = 0; i < limit; i++) {
      checked.push(value)
    }
    setChecked(checked)
  }

  columns.forEach(col => {
    if (col.valueGetter) {
      valueGetters[col.field] = col.valueGetter
    }
  })

  const prevPage = () => {
    if (page > 1) {
      fillChecked(false)
      setPage(page - 1)
    }
  }

  const nextPage = () => {
    if (page < pages) {
      fillChecked(false)
      setPage(page + 1)
    }
  }

  const toggleSort = (field) => {
    if (!sort || sort.field !== field) {
      setSort({ field, dir: 1 })
    } else if (sort.dir === 1) {
      setSort({ field, dir: -1 })
    } else {
      setSort(defaultSort)
    }
    fillChecked(false)
  }

  // const classes = useStyles()
  const firstItem = (page - 1) * limit + 1
  const lastItem = firstItem + limit - 1

  const sortedRows = rows && rows instanceof Array ? [...rows] : []
  if (sort && sort.field) {
    const getter = valueGetters[sort.field]
    sortedRows.sort((a, b) => {
      const _a = getter ? getter(a) : a[sort.field]
      const _b = getter ? getter(b) : b[sort.field]
      if (_a === _b) {
        return 0
      } else {
        return sort.dir * (_a < _b ? -1 : 1)
      }
    })
  }
  const filteredRows = sortedRows.slice(firstItem - 1, lastItem)

  let anyChecked = false
  let allChecked = true
  if (checkboxSelection) {
    for (let i = 0; i < limit; i++) {
      anyChecked = anyChecked || !!checked[i]
      allChecked = allChecked && !!checked[i]
    }
  }

  const handleCheckChange = (index, isChecked) => {
    checked[index] = isChecked
    if (onRowSelect) {
      onRowSelect(filteredRows[index], isChecked)
    }
    setChecked([ ...checked ])
  }

  const toggleCheck = (index) => {
    const isChecked = checked[index] = !checked[index]
    if (onRowSelect) {
      onRowSelect(filteredRows[index], isChecked)
    }
    setChecked([ ...checked ])
  }

  const clearBulk = () => {
    fillChecked(false)
  }

  const handleBulkCheck = (event) => {
    if (event.target.checked) {
      fillChecked(true)
    } else {
      fillChecked(false)
    }
  }

  return <ContextMenuWrapper actions={contextActions} onAction={onContextAction}>
    <Table>
      <TableHead>
        <TableRow>
          {checkboxSelection &&
            <TableCell
              padding="checkbox"
              sx={{ width: 50 }}
            >
              <Checkbox
                checked={allChecked}
                indeterminate={anyChecked && !allChecked}
                onChange={handleBulkCheck}
              />
            </TableCell>
          }
          {columns.map(column => (
            <TableCell
              key={column.field}
              // className={`${classes.headerCell}${sort && sort.field === column.field ? ` ${classes.headerCellSorted}` : ''}`}
              sx={{ width: column.width || 'auto', position: 'relative', '&:hover .MuiIconButton-root': { opacity: 1 } }}
              align={column.align}
            >
              {column.headerName}
              {(!('sortable' in column) || column.sortable === true) &&
                <IconButton size="small" sx={{ ml: 1, position: 'absolute', top: 12, right: 0, opacity: 0 }} onClick={() => toggleSort(column.field)}>
                {sort && sort.field === column.field && sort.dir === -1
                  ? <ArrowDownward fontSize="small"/>
                  : <ArrowUpward fontSize="small"/>
                }
                </IconButton>
              }
            </TableCell>
          ))}
          {contextActions &&
            <TableCell
              padding="checkbox"
              sx={{ width: 50 }}
            />
          }
        </TableRow>
      </TableHead>
      <TableBody>
        {filteredRows.map((row, index) => {
          const ctxParams = contextActions && contextParams
            ? contextParams(row)
            : {}

          return <TableRow
            key={index}
            hover
            selected={checked[index]||false}
            onClick={event => !event.target.closest('button') ? (onRowClick ? onRowClick(row) : toggleCheck(index)) : null}
          >
            {checkboxSelection &&
              <TableCell padding="checkbox">
                <Checkbox
                  checked={checked[index]||false}
                  onChange={(event) => handleCheckChange(index, event.target.checked)}
                />
              </TableCell>
            }
            {columns.map(column => {
              let value = ''
              let Cell = null

              if (column.renderCell) {
                Cell = column.renderCell
              } else if (column.valueGetter) {
                value = column.valueGetter(row)
              } else if (column.field in row) {
                value = row[column.field]
              }
              if (typeof value === 'object') {
                value = value.toString()
              }

              return <TableCell key={column.field} align={column.align}>
                {Cell ? <Cell {...row}/> : value}
              </TableCell>
            })}
            {contextActions && <TableCell padding="checkbox">
              <ContextMenuToggle reference={row.id} {...ctxParams}/>
            </TableCell>}
          </TableRow>
        })}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TableCell colSpan={numColumns}>
            <Box sx={{ display: 'flex' }}>
              {checkboxSelection && bulkActions && onBulkAction && anyChecked && <Box>
                {Object.keys(bulkActions).map(action => (
                  <Button variant="outlined" key={action} onClick={event => onBulkAction(action, event, checked, clearBulk)}>
                    {bulkActions[action].label}
                  </Button>
                ))}
              </Box>}
              <Box sx={{ flexGrow: 1 }}/>
              <Box>
                {rowsPerPageOptions && rowsPerPageOptions.length > 1 &&
                  <>
                    {locale.translate('rowsPerPage', 'DataTable')}:
                    <DropdownButton
                      size="small"
                      sx={{ color: 'inherit' }}
                      options={rowsPerPageOptions}
                      value={limit}
                      onChange={(option) => setLimit(option)}
                    />
                  </>
                }
                {pages <= 1
                  ? `${locale.translate('totalRows', 'DataTable')}: ${rows.length}`
                  : `${firstItem}-${lastItem} ${locale.translate('of', 'DataTable')} ${rows.length}`
                }
                <IconButton disabled={page <= 1} onClick={prevPage}>
                  <ChevronLeft/>
                </IconButton>
                <IconButton disabled={page >= pages} onClick={nextPage}>
                  <ChevronRight/>
                </IconButton>
              </Box>
            </Box>
          </TableCell>
        </TableRow>
      </TableFooter>
    </Table>
  </ContextMenuWrapper>
}

export default DataTable
