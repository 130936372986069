import { FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup } from '@mui/material'

const FormikRadio = ({ field, form: { errors, touched }, options, label }) => {
  const value = field.value || ''

  return <FormControl
      component="fieldset"
      error={touched[field.name] && !!errors[field.name]}
      variant="standard"
    >
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup
        {...field}
        value={value}
      >
        {options instanceof Array
          ? options.map(option => (
              <FormControlLabel
                key={option}
                value={option}
                control={<Radio />}
                label={option}
              />
            ))
          : Object.keys(options).map(key => (
              <FormControlLabel
                key={key}
                value={key}
                control={<Radio />}
                label={options[key]}
              />
            ))
        }
      </RadioGroup>
      {touched[field.name] && !!errors[field.name] && <FormHelperText error>{errors[field.name]}</FormHelperText>}
    </FormControl>
}

export default FormikRadio
