import { Box } from "@mui/system"
import { Button, Typography } from "@mui/material"
import { Form, Formik, Field } from "formik"
import FormikDatePicker from "../form/FormikDatePicker"
import * as Yup from "yup"
import config from "../../config"
import authFetch from "../../authFetch"

function click (node) {
  try {
    node.dispatchEvent(new MouseEvent('click'))
  } catch (e) {
    var evt = document.createEvent('MouseEvents')
    evt.initMouseEvent('click', true, true, window, 0, 0, 0, 80,
                        20, false, false, false, false, 0, null)
    node.dispatchEvent(evt)
  }
}

const validationSchema = Yup.object().shape({
  from: Yup.date().required(),
  to: Yup.date().required(),
})

const ExportPayments = ({ onCancel }) => {
  const exportExcel = async (from, to) => {
    const url = `${config.serverUrl}/payments/export?from=${from.toISOString()}&to=${to.toISOString()}`

    const response = await authFetch(url)

    const b64 = await response.text()

    const a = document.createElement('a')
    a.download = 'Betalingen.xlsx'
    a.rel = 'noopener'
    a.href = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + b64

    setTimeout(function () { URL.revokeObjectURL(a.href) }, 4E4) // 40s
    setTimeout(function () { click(a) }, 0)
  }

  const onSubmit = async (values, { setSubmitting }) => {
    await exportExcel(values.from, values.to)

    setSubmitting(false)

    if (onCancel) {
      onCancel()
    }
  }

  const initialValues = {
    from: new Date(),
    to: new Date(),
  }
  initialValues.from.setFullYear(initialValues.from.getFullYear() - 1)

  return <Formik
    initialValues={initialValues}
    validationSchema={validationSchema}
    onSubmit={onSubmit}
  >
    <Form>
      <Typography variant="body1">
        Selecteer de periode waarbinnen u alle betalingen wilt exporteren.
      </Typography>

      <Box sx={{ mt: 4 }}>
        <Field
          component={FormikDatePicker}
          name="from"
          label="Van"
          fullWidth
        />
      </Box>
      <Box sx={{ mt: 4 }}>
        <Field
          component={FormikDatePicker}
          name="to"
          label="Tot"
          fullWidth
        />
      </Box>
      <Box sx={{ mt: 4, display: 'flex' }}>
        <Button type="submit" variant="contained">Exporteren</Button>
        <Box sx={{ flexGrow: 1 }}/>
        <Button variant="outlined" onClick={onCancel}>Annuleren</Button>
      </Box>
    </Form>
  </Formik>
}

export default ExportPayments
