import { FormControl, FormHelperText, Typography } from "@mui/material"
import RichText from "../RichText"

const FormikRichText = ({ field, form: { errors, touched, setFieldValue }, ...props }) => {
  const isError = touched[field.name] && !!errors[field.name]
  const helperText = isError && errors[field.name]

  return <FormControl error={isError} fullWidth={props.fullWidth}>
    <Typography variant="body1">{props.label}</Typography>
    <RichText
      value={field.value}
      onChange={v => setFieldValue(field.name, v)}
      style={{ marginTop: 8 }}
      {...props}
    />
    {helperText && <FormHelperText error>{helperText}</FormHelperText>}
  </FormControl>
}

export default FormikRichText
