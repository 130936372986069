import config from "../config";
import authFetch from "../authFetch";


class Resend {
  async reminder(subscription_id) {
    const response = await authFetch(`${config.serverUrl}/resend/reminder/${subscription_id}`);
    const data = await response.json()
    return data
  }

  async confirm(subscription_id) {
    const response = await authFetch(`${config.serverUrl}/resend/confirm/${subscription_id}`);
    const data = await response.json()
    return data
  }

  async moved(subscription_id) {
    const response = await authFetch(`${config.serverUrl}/resend/moved/${subscription_id}`);
    const data = await response.json()
    return data
  }
}

export default Resend
