import { IconButton, Toolbar, Typography } from "@mui/material"
import OpenDayOverview from '../../components/open-days/OpenDayOverview'
import { useNavigate } from 'react-router-dom'
import { ArrowBack } from "@mui/icons-material"

const OpenDayCreate = () => {
  const navigate = useNavigate()

  const goBack = () => {
    navigate('/admin')
  }

  const onReady = () => {
    goBack()
  }

  const selectedItem = { editMode: true }

  return <>
    <Toolbar disableGutters>
      <IconButton onClick={goBack}>
        <ArrowBack/>
      </IconButton>
      <Typography variant="h6" sx={{ ml: 1 }}>
        Nieuwe open dag
      </Typography>
    </Toolbar>
    <OpenDayOverview {...selectedItem} onReady={onReady}/>
  </>
}

export default OpenDayCreate
