import { Box } from "@mui/system"
import { Typography } from "@mui/material"
import { useTheme } from "@mui/material/styles"
// import { makeStyles } from "@mui/styles"
import styled from "@emotion/styled"

const getRgb = (hex) => {
  const _hex = hex.replace(/^#/, '')
  if (_hex.length === 3) {
    return {
      red: parseInt(_hex[0], 16) * 17,
      green: parseInt(_hex[1], 16) * 17,
      blue: parseInt(_hex[2], 16) * 17,
    }
  }

  return {
    red: parseInt(_hex.substring(0, 2), 16),
    green: parseInt(_hex.substring(2, 4), 16),
    blue: parseInt(_hex.substring(4, 6), 16),
  }
}

const getHex = (rgb) => {
  return `#${rgb.red.toString(16).padStart(2, '0')}${rgb.green.toString(16).padStart(2, '0')}${rgb.blue.toString(16).padStart(2, '0')}`
}

const mixColors = (a, b, weight) => {
  const rgbA = getRgb(a)
  const rgbB = getRgb(b)
  const rgb = {
    red: Math.round(rgbA.red * weight + rgbB.red * (1 - weight)),
    green: Math.round(rgbA.green * weight + rgbB.green * (1 - weight)),
    blue: Math.round(rgbA.blue * weight + rgbB.blue * (1 - weight)),
  }

  return getHex(rgb)
}

const DomLinearProgress = styled.div({
  height: 4,
  display: 'flex',
  '& .Bar': {
    height: 4,
  }
}, ({ theme, color }) => ({
  background: theme.palette.divider,
  '& .ValueBar': {
    background: theme.palette[color||'primary'].main
  },
  '& .ValueBufferBar': {
    background: mixColors(theme.palette[color||'primary'].main, '#fff', .35)
  }
}))

// const useStyles = makeStyles(theme => {
//   return ({
//   root: {
//     height: 4,
//     display: 'flex',
//     background: theme.palette.divider,
//     '& .Bar': {
//       height: 4,
//     },
//     '& .ValueBar': {
//       background: props => theme.palette[props.color||'primary'].main
//     },
//     '& .ValueBufferBar': {
//       background: props => mixColors(theme.palette[props.color||'primary'].main, '#fff', .35)
//     },
//   }
// })});

const CustomLinearProgress = ({ value, valueBuffer, color }) => {
  // const classes = useStyles({ color })
  const theme = useTheme()

  return <DomLinearProgress theme={theme} color={color}>
    <div className="Bar ValueBar" style={{ width: `${value}%` }}/>
    <div className="Bar ValueBufferBar" style={{ width: `${valueBuffer - value}%` }}/>
  </DomLinearProgress>
}

const LinearProgressWithLabel = ({ value, valueBuffer, ...props }) => {
  let color = "primary"

  if (value >= 100) {
    color = 'error'
  } else if (value >= 80) {
    color = 'warning'
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <CustomLinearProgress variant="determinate" value={value} valueBuffer={valueBuffer} color={color} {...props} />
      </Box>
      {/* <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          value,
        )}%`}</Typography>
      </Box> */}
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          valueBuffer || value,
        )}%`}</Typography>
      </Box>
    </Box>
  )
}

export default LinearProgressWithLabel
