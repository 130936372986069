import { Button, Typography, TextField } from "@mui/material"
import { Box } from "@mui/system"
import { useState } from "react"
import { Send } from "@mui/icons-material"
import RichText from "../RichText"
import InteractiveButton from "../InteractiveButton"
import OpendaysClient from "../../clients/Opendays"
import Loader from "../Loader"
import { useQuery } from "react-query"
import useSettings from "../../hooks/useSettings"

const placeholders = {
  naam: 'Naam van de aanmelder',
  email: 'E-mailadres van de aanmelder',
  datum_aanmelding: 'Datum van de aanmelding'
}

const opendaysClient = new OpendaysClient()

const SendReminders = ({ opendayIds, onReady, onCancel }) => {
  const [working, setWorking] = useState(false)
  const [subject, setSubject] = useState(null)
  const [body, setBody] = useState(null)
  const [buttonCaption, setButtonCaption] = useState(null)

  const settings = useSettings()

  const { isLoading, data } = useQuery(
    'opendays-watinglist',
    () => opendaysClient.determineWaitingListUpdates(opendayIds),
    { retry: false }
  )

  const onSubmit = async () => {
    setWorking(true)

    const values = {
      subject: subject || settings?.waitingListSubject,
      body: body || settings?.waitingListBody,
      buttonCaption: buttonCaption || settings?.waitingListButton,
    }

    const data = await opendaysClient.sendWaitingListUpdates(opendayIds, values)
    if (data.error) {
      console.error(data.error)
    } else {
      if (onReady) {
        onReady()
      }
    }

    setWorking(false)
  }

  if (isLoading) {
    return <Loader/>
  }

  return <>
    <Box sx={{ p: 0 }}>
      <Box sx={{ mb: 3 }}>
        <Typography variant="paragraph">
          {data?.length === 0
            ? <>Er zijn geen mensen die een reminder krijgen</>
            : <>Er {data?.length === 1 ? 'is één persoon die een reminder zal krijgen' : `zijn ${data?.length} mensen die een reminder zullen krijgen`}</>
          }
        </Typography>
      </Box>
      {data?.length > -1
        ? <>
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>
                Onderwerp van de e-mail
              </Typography>
              <TextField
                value={subject === null ? settings.waitingListSubject : subject}
                onChange={event => setSubject(event.target.value)}
                fullWidth
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>
                Tekst in de e-mail
              </Typography>
              <RichText
                value={body === null ? settings.waitingListBody : body}
                onChange={v => setBody(v)}
                style={{ marginTop: 8 }}
                placeholders={placeholders}
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>
                Tekst in de knop
              </Typography>
              <TextField
                value={buttonCaption === null ? settings.waitingListButton : buttonCaption}
                onChange={event => setButtonCaption(event.target.value)}
                fullWidth
              />
            </Box>
          </>
        : null
      }
    </Box>

    <Box sx={{ textAlign: 'right' }}>
      <Button onClick={onCancel}>Annuleren</Button>
      {data?.length > -1
        ? <>
            <Box sx={{ display: 'inline-block', width: 16 }}/>
            <InteractiveButton variant="contained" onClick={onSubmit} startIcon={<Send/>} working={working}>Versturen</InteractiveButton>
          </>
        : null
      }
    </Box>
  </>
}

export default SendReminders
