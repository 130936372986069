import { Box } from '@mui/system'
import { Button, CircularProgress } from '@mui/material'

const InteractiveButton = ({ children, working, ...props }) => {
  return <Box sx={{ m: 1, position: 'relative', display: 'inline-block' }}>
    <Button
      autoFocus
      disabled={working}
      {...props}
    >
      {children}
    </Button>
    {working && (
      <CircularProgress
        size={24}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          marginTop: '-12px',
          marginLeft: '-12px',
        }}
      />
    )}
  </Box>
}

export default InteractiveButton
