import { Box } from "@mui/system"
import { Container } from "@mui/material"

const Frontend = ({ children }) => {
  return <Container maxWidth="sm">
    <Box
      sx={{
        py: 2
      }}
    >
      <Box sx={{ mb: 5, mt: 2 }}>
        {/*<img src="/logo.svg" style={{ width: 144 }} alt="NHLStenden"/>*/}
        <img src="/logo-nhlstenden.png" style={{ width: 144 }} alt="NHLStenden"/>
      </Box>
      <Box>
        {children}
      </Box>
    </Box>
  </Container>
}

export default Frontend
