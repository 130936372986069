import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material"
import { useState } from "react"
import InteractiveButton from "./InteractiveButton"

const Confirm = ({ open, title, message, confirmCaption, confirmColor, onOk, onClose }) => {
  const [working,setWorking] = useState(false)

  const closeConfirm = () => {
    setWorking(false)
    if (onClose) {
      onClose()
    }
  }

  const onOkClick = async () => {
    setWorking(true)
    if (onOk) {
      await onOk()
    }
    setWorking(false)
    if (onClose) {
      onClose()
    }
  }

  return <Dialog
    open={!!open}
    onClose={closeConfirm}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      {title}
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {message}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={closeConfirm}>Annuleren</Button>
      <InteractiveButton
        working={working}
        onClick={onOkClick}
        color={confirmColor}
      >
        {confirmCaption || 'OK'}
      </InteractiveButton>
    </DialogActions>
  </Dialog>
}

export default Confirm
