import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import auth from '../auth'
import Loader from './Loader'
import NotAllowed from './NotAllowed'

const Guard = ({ role, user, children }) => {
  const [allowed,setAllowed] = useState(false)
  const [working,setWorking] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    const checkAllowed = async () => {
      if (!auth.isLoggedIn()) {
        if (auth.canRefresh()) {
          setWorking(true)
          const success = await auth.refresh()
          setWorking(false)
          if (!success) {
            setAllowed(false)
            navigate('/login')
            return
          }
          if (window?.location?.search) {
            navigate(window.location.pathname)
          }
        } else {
          setWorking(true)
          setAllowed(false)
          navigate('/login')
          return
        }
      }

      if (role && auth.getRole() !== role) {
        setAllowed(false)
        return
      }

      if (user && user !== true && auth.getUser() !== user) {
        setAllowed(false)
        return
      }

      setAllowed(true)
    }

    checkAllowed()
  }, [role, user, navigate])

  return allowed
    ? children
    : (working ? <Loader/> : <NotAllowed/>)
}

export default Guard
