import { Box } from "@mui/system"
import { Button, Grid } from "@mui/material"
import { Close } from "@mui/icons-material"
import { Field, Form, Formik } from "formik"
import { dateFromString } from "../../functions"
import FormikDatePicker from "../form/FormikDatePicker"
import FormikTextField from "../form/FormikTextField"
import Opendays from "../../clients/Opendays"
import InteractiveButton from "../InteractiveButton"
import FormikCheckbox from "../form/FormikCheckbox"
import * as Yup from 'yup'
import { useState } from "react"

const opendaysClient = new Opendays()

const numberTransform = (value, origValue) => {
  if (typeof origValue === 'string') {
    return parseFloat(origValue.replace(/,/, '.'))
  }
  return origValue
}

const validationSchema = Yup.object().shape({
  date: Yup.date().required('Datum is verplicht'),
  startTime: Yup.string().matches(/\d{1,2}:\d{2}/, 'Vul een geldige tijd in: \'UU:MM\'').required('Starttijd is verpicht'),
  endTime: Yup.string().matches(/\d{1,2}:\d{2}/, 'Vul een geldige tijd in: \'UU:MM\'').required('Eindtijd is verpicht'),
  description: Yup.string(),
  capacity: Yup.number().integer('Vul een geheel getal in').min(1, 'Aantal plekken is verplicht').required('Aantal plekken is verplicht'),
  costs: Yup.number().transform(numberTransform).required('Aantal plekken is verplicht'),
  isOpen: Yup.boolean(),
  openFrom: Yup.date().required('Deze datum is verplicht'),
  openTo: Yup.date().required('Deze datum is verplicht'),
})

const FormField = ({ width, children }) => {
  return <Grid item xs={width||12}>
    <Box sx={{ p: 1 }}>
      {children}
    </Box>
  </Grid>
}

const OpenDayEdit = (props) => {
  const [working,setWorking] = useState(false)

  const toDate = new Date()
  toDate.setMonth(toDate.getMonth() + 1)

  const initialValues = {
    date: props.date ? dateFromString(props.date) : null,
    startTime: props.startTime ? props.startTime.substring(0, 5) : '10:00',
    endTime: props.endTime ? props.endTime.substring(0, 5) : '17:00',
    description: props.description || '',
    capacity: props.capacity || 0,
    costs: props.costs || 0,
    isOpen: !!props.isOpen,
    openFrom: props.openFrom ? new Date(props.openFrom) : new Date(),
    openTo: props.openTo ? new Date(props.openTo) : toDate
  }

  const onSubmit = async (values, { setSubmitting }) => {
    setWorking(true)

    const postValues = { ...values, costs: numberTransform(null, values.costs) }
    postValues.date.setHours(12)

    const data = props.id
      ? await opendaysClient.update(props.id, postValues)
      : await opendaysClient.create(postValues, props)

    if (props.onReady) {
      props.onReady(data)
    }

    setWorking(false)
    setSubmitting(false)
  }


  return <Formik
    initialValues={initialValues}
    validationSchema={validationSchema}
    onSubmit={onSubmit}
  >
    <Form>
      <Grid container>
        <FormField>
          <Field
            name="date"
            label="Datum"
            component={FormikDatePicker}
          />
        </FormField>
        <FormField width={6}>
          <Field
            name="startTime"
            label="Begintijd"
            component={FormikTextField}
          />
        </FormField>
        <FormField width={6}>
          <Field
            name="endTime"
            label="Eindtijd"
            component={FormikTextField}
          />
        </FormField>
        <FormField width={6}>
          <Field
            name="capacity"
            label="Aantal plekken"
            component={FormikTextField}
          />
        </FormField>
        <FormField width={6}>
          <Field
            name="costs"
            label="Kosten per persoon"
            component={FormikTextField}
          />
        </FormField>
        <FormField width={12}>
          <Field
            name="description"
            label="Opmerkingen"
            component={FormikTextField}
          />
        </FormField>
        <FormField width={12}>
          <Field
            name="isOpen"
            label="Open voor inschrijving"
            component={FormikCheckbox}
          />
        </FormField>
        <FormField width={6}>
          <Field
            name="openFrom"
            label="Aanmelden mogelijk vanaf"
            component={FormikDatePicker}
          />
        </FormField>
        <FormField width={6}>
          <Field
            name="openTo"
            label="Aanmelden mogelijk tot en met"
            component={FormikDatePicker}
          />
        </FormField>
        <FormField>
          <Box sx={{ display: 'flex' }}>
            <InteractiveButton variant="contained" type="submit" working={working}>Opslaan</InteractiveButton>
            {props.onReady && <>
              <Box sx={{ flexGrow: 1 }}/>
              <Button startIcon={<Close/>} onClick={props.onReady}>
                Annuleren
              </Button>
            </>}
          </Box>
        </FormField>
      </Grid>
    </Form>
  </Formik>
}

export default OpenDayEdit
