import { Box } from "@mui/system"
import { Alert, Chip } from "@mui/material"
import { Delete as DeleteIcon } from "@mui/icons-material"
import { humanReadableDate, dateFromString } from "../../functions"
import { useQuery } from "react-query"
import SubscriptionsClient from "../../clients/Subscriptions"
import Loader from "../../components/Loader"
import Confirm from "../../components/Confirm"
import { useState } from "react"
import DataTable from "../../components/DataTable"

const subscriptionsClient = new SubscriptionsClient()

const statuses = {
  init: {
    label: 'Betaling niet gestart',
    color: 'primary'
  },
  paid: {
    label: 'Betaald',
    color: 'success'
  },
  unpaid: {
    label: 'Nog niet betaald',
    color: 'warning'
  },
  open: {
    label: 'Open',
    color: 'primary'
  },
  canceled: {
    label: 'Geannuleerd',
    color: 'error'
  },
  expired: {
    label: 'Verlopen',
    color: 'error'
  },
  failed: {
    label: 'Mislukt',
    color: 'error'
  },
  authorized: {
    label: 'Geautoriseerd',
    color: 'secondary'
  },
  pending: {
    label: 'Pending',
    color: 'secondary'
  },
}

const actions = {
  delete: {
    label: 'Verwijderen',
    icon: DeleteIcon
  },
}

const columns = [
  {
    field: 'name',
    headerName: 'Naam',
    valueGetter: params => `${params.firstName} ${params.middleName} ${params.lastName}`
  },
  {
    field: 'email',
    headerName: 'E-mailadres'
  },
  {
    field: 'date',
    headerName: 'Open dag',
    valueGetter: params => params.openday?.date,
    renderCell: params => <>{humanReadableDate(new Date(params.openday?.date))}</>
  },
  {
    field: 'nrOfPersons',
    headerName: 'Aantal personen',
  },
  {
    field: 'status',
    headerName: 'Status',
    renderCell: params => {
      let status = params.payment?.status || params.status
      return statuses[status]
        ? <Chip label={statuses[status].label} color={statuses[status].color}/>
        : status
    }
  }
]

const OpenPayments = () => {
  const [deletingSubscription, setDeletingSubscription] = useState(null)

  const { isLoading, data: subscriptions, refetch } = useQuery('unpaid-subcriptions', subscriptionsClient.getUnpaid, { retry: false })

  const confirmDeleteItem = async () => {
    await subscriptionsClient.delete(deletingSubscription.id)
    setDeletingSubscription(null)
    refetch()
  }

  const onContextAction = (reference, action) => {
    if (action === 'delete') {
      const subscription = subscriptions.find(sub => sub.id === reference)
      setDeletingSubscription(subscription)
    }
  }

  if (isLoading) {
    return <Loader/>
  }

  return <>
    <Box sx={{ py: 2 }}>
      <DataTable
        columns={columns}
        rows={subscriptions}
        contextActions={actions}
        onContextAction={onContextAction}
      />
    </Box>

    <Confirm
      open={!!deletingSubscription}
      onClose={() => setDeletingSubscription(null)}
      title='Weet u zeker dat u deze aanmelding wilt verwijderen?'
      message={deletingSubscription ? <>U staat op het punt om de aanmelding van {deletingSubscription?.firstName}{deletingSubscription?.middleName ? ' ' + deletingSubscription.middleName : ''}{deletingSubscription?.lastName ? ' ' + deletingSubscription.lastName : ''} voor de open dag van {humanReadableDate(dateFromString(deletingSubscription?.openday?.date))} te verwijderen. <Alert severity="warning" sx={{ mt: 2 }}>Let op! Dit kan niet meer ongedaan gemaakt worden!</Alert></> : ''}
      onOk={confirmDeleteItem}
      confirmCaption='Verwijderen'
      confirmColor='error'
    />
  </>
}

export default OpenPayments
